/* Font Family */
/* Borda arredondada */
body > #root > section > main .conditionsBox {
  padding-bottom: 15px;
  border-bottom: 1px solid #dedede;
}
body > #root > section > main .conditionsBox > .headLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .conditionsBox > .headLine > .name {
  font-size: 1.1em;
  font-weight: 600;
}
body > #root > section > main .conditionsBox > .headLine > button {
  font-size: 1em;
  text-decoration: underline;
  color: #4d4d4d;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
body > #root > section > main .conditionsBox .description {
  padding: 12px 0 10px;
  font-size: 0.95em;
}
body > #root > section > main .conditionsBox .ReactCollapse--collapse {
  transition: height 500ms;
}
