/* Font Family */
/* Borda arredondada */
body > #root .balon,
body > #root .resp {
  font-size: 1.1em;
  font-weight: 300;
}
body > #root .balon > .text,
body > #root .resp > .text {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  max-width: 80%;
  padding: 15px;
}
body > #root .balon > .text > svg,
body > #root .resp > .text > svg {
  margin-right: 8px;
}
body > #root .balon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #FFF;
}
body > #root .balon > .text {
  background-color: #0e3675;
  border-radius: 5px 15px 15px 15px;
  -moz-border-radius: 5px 15px 15px 15px;
  -webkit-border-radius: 5px 15px 15px 15px;
}
body > #root .resp {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #030C1A;
}
body > #root .resp > .text {
  background-color: #ebf2fd;
  border-radius: 15px 5px 15px 15px;
  -moz-border-radius: 15px 5px 15px 15px;
  -webkit-border-radius: 15px 5px 15px 15px;
}
body > #root .question {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root .question > h3 {
  font-size: 0.9em;
  font-weight: 600;
  color: #FFF;
}
body > #root .question > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 10px 20px;
  color: #FFF;
  cursor: pointer;
  background-color: #0e3675;
  border: none;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}
body > #root .question > button:not(:first-child) {
  margin-top: 5px;
}
body > #root .question > button > svg {
  margin-right: 8px;
}
body > #root .modal {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: fixed;
  top: 71px;
  bottom: 0;
  right: 0;
  width: 450px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root .modal > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: auto;
  padding: 50px 20px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(6, 23, 49, 0.98);
}
body > #root .modal > .box > .icon {
  padding: 10px;
  width: 100px;
  background-color: #FFF;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root .modal > .box > .text {
  margin-top: 25px;
  padding: 0 20px;
  font-size: 1.2em;
  font-weight: 300;
  color: #FFF;
  text-align: center;
}
body > #root .modal > .box > .text a {
  color: #FFF;
  cursor: pointer;
  text-decoration: underline;
}
body > #root .modal > .box > a,
body > #root .modal > .box > button {
  margin-top: 25px;
  padding: 15px 30px;
  color: #FFF;
  text-align: center;
  width: 70%;
  background-color: #347be8;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  cursor: pointer;
}
body > #root .modal > .box > .cancel {
  background-color: #FFF;
  color: #030C1A;
}
