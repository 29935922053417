/* Font Family */
/* Borda arredondada */
body > #root > section > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  height: 35px;
}
@media screen and (max-width: 767px) {
  body > #root > section > main > .header {
    flex: 0 0 auto !important;
  }
}
body > #root > section > main > .header > h3 {
  font-size: 0.9rem;
  color: #c3c3c3;
}
body > #root > section > main > .header > h3 > b {
  color: #C0C0C0;
}
body > #root > section.cliBill > main > .billsBox,
body > #root > section.cliBillCheck > main > .billsBox,
body > #root > section.cliBill > main > .billBox,
body > #root > section.cliBillCheck > main > .billBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox,
  body > #root > section.cliBillCheck > main > .billsBox,
  body > #root > section.cliBill > main > .billBox,
  body > #root > section.cliBillCheck > main > .billBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .header > .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  width: 31.75%;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .header > .searchBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .header > .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 30px 8px 10px;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .header > .searchBox > #search {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .header > .searchBox > #search:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
  outline-width: 0;
}
body > #root > section.cliBill > main > .header > .searchBox > #search:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > section.cliBill > main > .header > .searchBox > #search:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > section.cliBill > main > .header > .searchBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  color: #EA4335;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .header > .searchBox > button {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1rem 1.5rem 1rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #D0D0D0;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox > header {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox > header > .blank {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox > header > .blank {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox > header > .provider {
  width: 20%;
}
body > #root > section.cliBill > main > .billsBox > header > .modality {
  width: 15%;
}
body > #root > section.cliBill > main > .billsBox > header > .date {
  width: 20%;
}
body > #root > section.cliBill > main > .billsBox > header > .status {
  width: 15%;
}
body > #root > section.cliBill > main > .billsBox > header > .btns {
  width: 20%;
}
body > #root > section.cliBill > main > .billsBox > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 75vh;
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C0C0C0 #e3e3e3;
  /* Works on Chrome, Edge, and Safari */
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox > section {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox > section > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBill > main > .billsBox > section > .noData {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBill > main > .billsBox > section::-webkit-scrollbar-track {
  background: #e3e3e3;
}
body > #root > section.cliBill > main > .billsBox > section::-webkit-scrollbar {
  width: 5px;
}
body > #root > section.cliBill > main > .billsBox > section::-webkit-scrollbar-thumb {
  margin: 2px;
  background-color: #C0C0C0;
}
body > #root > section.cliBillCheck > main > .billBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 57.5vh;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 0 0 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #003063;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > h3 {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > h3::before {
  position: absolute;
  content: '';
  bottom: -5px;
  width: 30px;
  height: 2px;
  background-color: #007afc;
}
body > #root > section.cliBillCheck > main > .billBox > section > h3:not(:first-child) {
  margin: 50px 0 30px;
}
body > #root > section.cliBillCheck > main > .billBox > section > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .line {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .line:not(:nth-child(2)) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #F0F0F0;
}
body > #root > section.cliBillCheck > main > .billBox > section > .line > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-right: 20px;
  width: 25%;
  font-size: 0.8rem;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .line > * {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .line > * > label {
  font-weight: 600;
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .consumptions {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-right: 20px;
  width: 25%;
  font-size: 0.8rem;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column > .month:not(:first-child) {
  margin-top: 25px;
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column > .month > h4 {
  margin-bottom: 5px;
  font-size: 0.9rem;
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column > .month > .infos {
  font-size: 0.8rem;
  color: #666;
}
body > #root > section.cliBillCheck > main > .billBox > section > .consumptions > .column > .month > .infos > * {
  margin-top: 3px;
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .othersLine {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine:not(:last-child) {
  margin-bottom: 25px;
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-right: 20px;
  width: 25%;
  font-size: 0.8rem;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > section > .othersLine > * {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine > * > h4 {
  margin-bottom: 5px;
  font-size: 0.9rem;
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine > * > .infos {
  font-size: 0.8rem;
  color: #666;
}
body > #root > section.cliBillCheck > main > .billBox > section > .othersLine > * > .infos > * {
  margin-top: 3px;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
  height: 17.5vh;
  border-top: 1px solid #C0C0C0;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 30px;
  padding: 45px 0 20px;
  width: 320px;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .selectbox > button {
  width: 50%;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > button,
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > button,
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > a {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > button.save,
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > a.save {
  width: 60%;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > button.cancel,
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > a.cancel {
  width: 40%;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > button:not(:first-child),
body > #root > section.cliBillCheck > main > .billBox > .formBox > .buttons > .line > a:not(:first-child) {
  margin-left: 10px;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px 0;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > label {
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > textarea {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  color: #666;
  border: 1px solid #C0C0C0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > textarea {
    flex: 0 0 auto !important;
  }
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > textarea:disabled {
  color: #C0C0C0;
  background-color: #F0F0F0;
  border: 1px solid #D0D0D0;
}
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > textarea:focus,
body > #root > section.cliBillCheck > main > .billBox > .formBox > .desc > textarea:active {
  color: #333;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #999;
  outline: none;
}
