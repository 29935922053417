/* Font Family */
/* Borda arredondada */
body > #root > section > main .oppsEcoBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  overflow: hidden;
  background-color: #FFF;
  border: solid 2px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section > main .oppsEcoBox * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > section > main .oppsEcoBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px 10px 80px;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section > main .oppsEcoBox > header > .titles {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .oppsEcoBox > header > .titles > h3 {
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #4d4d4d;
}
body > #root > section > main .oppsEcoBox > header > .hover {
  margin-top: 2px;
  margin-left: 30px;
  font-size: 1.1rem;
  color: #a7a7a7;
}
body > #root > section > main .oppsEcoBox > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .oppsEcoBox > .content > .body {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px 20px;
}
body > #root > section > main .oppsEcoBox > .content > .body > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 36%;
}
body > #root > section > main .oppsEcoBox > .content > .body > div.right {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 64%;
  border-left: 1px dashed #D0D0D0;
}
body > #root > section > main .oppsEcoBox > .content > .body > div > span {
  font-size: 0.9rem;
  color: #999;
  text-align: center;
}
body > #root > section > main .oppsEcoBox > .content > .body > div > span.number {
  font-size: 2.8rem;
  font-weight: 600;
  color: #666;
}
body > #root > section > main .oppsEcoBox > .content > .body > div > span.value {
  margin-top: 10px;
  font-size: 2rem;
  font-weight: 600;
  color: #004996;
}
body > #root > section > main .oppsEcoBox > .content > a#btns {
  padding: 10px;
  width: 100%;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .oppsEcoBox > header {
    padding: 15px 20px 10px;
  }
  body > #root > section > main .oppsEcoBox > header > .titles {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section > main .oppsEcoBox > header > .titles > h3 {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body {
    margin-bottom: 0;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div {
    width: 38%;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div.right {
    padding: 0 0 0 20px;
    width: 62%;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div > .upDown > span {
    font-size: 0.9rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div > .upDown > span.number {
    font-size: 2.6rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div > .upDown > span.value {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section > main .oppsEcoBox > header {
    padding: 15px 20px 10px 30px;
  }
  body > #root > section > main .oppsEcoBox > header > .titles > h3 {
    font-size: 0.9rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body {
    margin-bottom: 0;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div {
    width: 38%;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div span {
    font-size: 0.75rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div.right .value {
    font-size: 1.7rem;
  }
  body > #root > section > main .oppsEcoBox > .content > .body > div.left .number {
    font-size: 1.9rem;
  }
  body > #root > section > main .oppsEcoBox > .content > a#btns {
    font-size: 0.7rem;
  }
}
