/* Font Family */
/* Borda arredondada */
body > #root > section.opp.model > main.step5,
body > #root > section.opp.model > main.step6,
body > #root > section.opp.model > main.step7 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  background-color: #FFF;
}
body > #root > section.opp.model > main.step5 > .signatureOpps,
body > #root > section.opp.model > main.step6 > .signatureOpps,
body > #root > section.opp.model > main.step7 > .signatureOpps {
  margin-bottom: 50px;
  text-align: center;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > h1,
body > #root > section.opp.model > main.step6 > .signatureOpps > h1,
body > #root > section.opp.model > main.step7 > .signatureOpps > h1 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.8em;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > h1 > span,
body > #root > section.opp.model > main.step6 > .signatureOpps > h1 > span,
body > #root > section.opp.model > main.step7 > .signatureOpps > h1 > span {
  margin-left: 10px;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > p,
body > #root > section.opp.model > main.step6 > .signatureOpps > p,
body > #root > section.opp.model > main.step7 > .signatureOpps > p {
  font-size: 1.2em;
  color: #666;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > img,
body > #root > section.opp.model > main.step6 > .signatureOpps > img,
body > #root > section.opp.model > main.step7 > .signatureOpps > img {
  margin: 30px 0;
  width: 100px;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > .box-info,
body > #root > section.opp.model > main.step6 > .signatureOpps > .box-info,
body > #root > section.opp.model > main.step7 > .signatureOpps > .box-info {
  font-size: 14px;
  margin: 30px auto;
  padding: 12px 18px;
  color: #000 !important;
  border: 1px solid #FFCC00;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  width: 80%;
}
body > #root > section.opp.model > main.step5 > .signatureOpps > .box-save,
body > #root > section.opp.model > main.step6 > .signatureOpps > .box-save,
body > #root > section.opp.model > main.step7 > .signatureOpps > .box-save {
  font-size: 14px;
  display: block;
  margin: 30px auto;
  padding: 12px 18px;
  color: #000 !important;
  background-color: #FFCC00;
  border: 1px solid #FFCC00;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  width: 80%;
}
@media screen and (max-width: 991px) {
  body > #root > section.opp.model > main.step5,
  body > #root > section.opp.model > main.step6,
  body > #root > section.opp.model > main.step7 {
    height: calc(100vh - 125px);
    /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 125px);
  }
  body > #root > section.opp.model > main.step5 > .signatureOpps,
  body > #root > section.opp.model > main.step6 > .signatureOpps,
  body > #root > section.opp.model > main.step7 > .signatureOpps {
    margin-bottom: 10px;
  }
}
