/* Font Family */
/* Borda arredondada */
body > #root > section > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  height: 35px;
}
body > #root > section.cli > main > .header > .titleAndButton {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.cli > main > .header > .titleAndButton > .box {
  margin-left: 15px;
}
body > #root > section.cli > main > .header > .titleAndButton > .box > button {
  background-color: #457EF6;
  color: #FFF;
  border: none;
  padding: 5px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  cursor: pointer;
}
body > #root > section.cli > main > .header > .titleAndButton > .box > button > svg {
  margin-right: 5px;
}
body > #root > section.cli > main > .header > .titleAndButton > .box > button:hover {
  background-color: #145cf4;
}
body > #root > section.cli > main > .header .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  width: 31.75%;
}
body > #root > section.cli > main > .header .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 30px 8px 10px;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.cli > main > .header .searchBox > #search:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
  outline-width: 0;
}
body > #root > section.cli > main > .header .searchBox > #search:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > section.cli > main > .header .searchBox > #search:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > section.cli > main > .header .searchBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  color: #EA4335;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
body > #root > section.cli > main > .clientsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.cli > main > .clientsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section.cli > main > .clientsBox > header > .provider,
body > #root > section.cli > main > .clientsBox > header > .modality,
body > #root > section.cli > main > .clientsBox > header > .btns {
  padding-left: 30px;
}
body > #root > section.cli > main > .clientsBox > header > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 45%;
}
body > #root > section.cli > main > .clientsBox > header > .provider {
  width: 20%;
}
body > #root > section.cli > main > .clientsBox > header > .modality {
  width: 15%;
}
body > #root > section.cli > main > .clientsBox > header > .btns {
  width: 35%;
}
body > #root > section.cli > main > .clientsBox > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 75vh;
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C0C0C0 #e3e3e3;
  /* Works on Chrome, Edge, and Safari */
}
body > #root > section.cli > main > .clientsBox > section > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
body > #root > section.cli > main > .clientsBox > section::-webkit-scrollbar-track {
  background: #e3e3e3;
}
body > #root > section.cli > main > .clientsBox > section::-webkit-scrollbar {
  width: 5px;
}
body > #root > section.cli > main > .clientsBox > section::-webkit-scrollbar-thumb {
  margin: 2px;
  background-color: #C0C0C0;
}
body > #root > section.cliEdit > main > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.cliEdit > main > form .mask > svg {
  padding: 2px;
  margin-left: auto;
  margin-top: -35px;
  margin-right: 10px;
}
body > #root > section.cliEdit > main > form .mask > svg.loading {
  color: #EA4335;
}
body > #root > section.cliEdit > main > form .mask > svg.error {
  color: #EA4335;
}
body > #root > section.cliEdit > main > form .mask > svg.success {
  color: #22A822;
}
body > #root > section.cliEdit > main > form .mask > small {
  margin-top: 10px;
}
body > #root > section.cliEdit > main > form > .fields {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 30px;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
body > #root > section.cliEdit > main > form > .fields > div {
  width: 50%;
}
body > #root > section.cliEdit > main > form > .fields > div:not(:first-child) {
  margin-left: 10%;
}
body > #root > section.cliEdit > main > form > .fields > div > h2 {
  margin-bottom: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #a6a6a6;
}
body > #root > section.cliEdit > main > form > .fields > div > div {
  margin-top: 15px;
}
body > #root > section.cliEdit > main > form > .fields > div > div > label {
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #595959;
}
body > #root > section.cliEdit > main > form > .fields > div > div > input {
  margin: 1px 0 0;
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  background-color: transparent;
  color: #666;
  border: 1px solid #D0D0D0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.cliEdit > main > form > .fields > div > div > input:focus,
body > #root > section.cliEdit > main > form > .fields > div > div > input:active {
  color: #333;
  background-color: rgba(255, 255, 255, 0.5);
  border-color: #9a9a9a;
  outline: none;
}
body > #root > section.cliEdit > main > form > .fields > div > div > input::-webkit-input-placeholder {
  color: #999;
}
body > #root > section.cliEdit > main > form > .fields > div > div > input:-ms-input-placeholder {
  color: #999;
}
body > #root > section.cliEdit > main > form > .fields > div > div > input::placeholder {
  color: #999;
}
body > #root > section.cliEdit > main > form > .fields > div > div.line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 25px;
}
body > #root > section.cliEdit > main > form > .fields > div > div.line > .checkbox > label {
  padding-left: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #595959;
}
body > #root > section.cliEdit > main > form > .fields > div > div.line > .checkbox > input[type="checkbox"] {
  width: 1.5em;
  height: 1.5em;
  border-color: #C0C0C0;
}
body > #root > section.cliEdit > main > form > .fields > div > div.line > .checkbox > input[type="checkbox"]::before {
  width: 1.5em;
  height: 1.5em;
  box-shadow: inset 1.5em 1.5em #006ee3;
}
body > #root > section.cliEdit > main > form > .fields > div > div.line > .selectbox {
  margin-left: 30px;
  border-color: #C0C0C0;
}
body > #root > section.cliEdit > main > form > .fields > div > hr {
  margin: 25px 0;
  height: 1px;
  background-color: #eaeaea;
  border: none;
}
body > #root > section.cliEdit > main > form > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 2rem;
}
body > #root > section.cliEdit > main > form > .btns > a,
body > #root > section.cliEdit > main > form > .btns > button {
  margin-left: 15px;
  padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
}
body > #root > section.cliEdit > main > form > .btns > a:not(:first-child),
body > #root > section.cliEdit > main > form > .btns > button:not(:first-child) {
  margin-left: 15px;
}
