/* Font Family */
/* Borda arredondada */
body > #root > .rep .tariffsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  background-color: #FFF;
  border: solid 2px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > .rep .tariffsBox * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > .rep .tariffsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px 10px 80px;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > .rep .tariffsBox > header > .titles {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > .rep .tariffsBox > header > .titles > h3 {
  margin-bottom: 3px;
  font-size: 1.1rem;
  color: #6B7280;
}
body > #root > .rep .tariffsBox > header > .titles > h4 {
  margin-bottom: 3px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #6B7280;
}
body > #root > .rep .tariffsBox > header > .hover {
  margin-top: 2px;
  margin-left: 30px;
  font-size: 1.1rem;
  color: #a7a7a7;
}
body > #root > .rep .tariffsBox > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
}
body > #root > .rep .tariffsBox > .content > .comingSoon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
body > #root > .rep .tariffsBox > .content > .comingSoon > span {
  font-size: 1.2em;
  font-weight: 600;
  color: #197e19;
}
body > #root > .rep .tariffsBox > .content > .loading {
  margin: 0;
}
body > #root > .rep .tariffsBox > .content > .itemTariff {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 20px;
  border: none;
  border-bottom: 2px solid #D0D0D0;
}
body > #root > .rep .tariffsBox > .content > .itemTariff:last-child {
  border: none;
}
body > #root > .rep .tariffsBox > .content > .itemTariff > .content_box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  padding-right: 15px;
  color: #6B7280 !important;
}
body > #root > .rep .tariffsBox > .content > .itemTariff > .content_box > h4 {
  font-size: 1em;
  max-width: 100%;
}
body > #root > .rep .tariffsBox > .content > .itemTariff > .content_box > label {
  margin-top: 10px;
}
body > #root > .rep .tariffsBox > .content > .itemTariff > .click > p {
  font-size: 0.8em;
  white-space: nowrap;
}
body > #root > .rep .tariffsBox > .content > .itemTariff > .click > p > span {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  body > #root > .rep .tariffsBox > header {
    padding: 15px 20px 10px;
  }
  body > #root > .rep .tariffsBox > header > .titles > h3 {
    margin-bottom: 5px;
    font-size: 1rem;
  }
  body > #root > .rep .tariffsBox > .content > .loading {
    padding: 30px;
  }
  body > #root > .rep .tariffsBox > .content > .itemTariff {
    padding: 20px;
  }
  body > #root > .rep .tariffsBox > .content > .itemTariff > h4 {
    margin: 0 0 10px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > .rep .tariffsBox > header {
    padding: 15px 20px 10px 50px;
  }
  body > #root > .rep .tariffsBox > header > .titles > h3 {
    font-size: 0.9rem;
  }
  body > #root > .rep .tariffsBox > .content > .itemTariff {
    padding: 15px 20px;
  }
  body > #root > .rep .tariffsBox > .content > .itemTariff > h4 {
    margin: 0 0 10px !important;
  }
}
body > #root > #modalBox > .modalBody > .modalContent > h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #666;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0 25px;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 37%;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box:not(:first-child) {
  margin-left: 5px;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box:last-child {
  width: 26%;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box:last-child > .price {
  color: #26ae7c;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box > small {
  font-weight: 500;
  color: #999;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box > .price {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.7rem;
  font-weight: bolder;
}
body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box > .price > img {
  margin-left: 10px;
}
body > #root > #modalBox > .modalBody > .modalContent > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
  padding: 20px;
  font-size: 1rem;
  color: #666;
  text-align: center;
  background-color: rgba(52, 211, 153, 0.1);
  border: 2px reped #34D399;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > #modalBox > .modalBody > .modalContent > .infos.err {
  font-size: 0.9rem;
  line-height: 1.3rem;
  background-color: rgba(223, 13, 13, 0.1);
  border-color: #DF0D0D;
}
body > #root > #modalBox > .modalBody > .modalContent > .note {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
  font-size: 0.8rem;
  line-height: 1.4rem;
  color: #808080;
}
body > #root > #modalBox > .modalBody > .modalContent > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.2rem;
  color: #333;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
@media screen and (max-width: 991px) {
  body > #root > #modalBox > .modalBody > .modalContent > h4 {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .consBox {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 30px 0 25px;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100% !important;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box:not(:first-child) {
    margin-top: 20px;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box > .price {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    font-size: 1.6rem;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .consBox > .box > .price > img {
    margin-left: 15px;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .infos {
    margin-bottom: 20px;
    padding: 15px;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .infos.err {
    font-size: 0.8rem;
  }
  body > #root > #modalBox > .modalBody > .modalContent > .note {
    margin-bottom: 20px;
    font-size: 0.75rem;
    line-height: 1.2rem;
  }
  body > #root > #modalBox > .modalBody > .modalContent > button {
    font-size: 0.8rem;
  }
}
