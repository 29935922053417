/* Font Family */
/* Borda arredondada */
body > #root > section.opp.model > main.step5 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 50px;
  text-align: center;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > h1 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.8em;
  color: #333;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > h1 > span {
  margin-left: 10px;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > img {
  margin-top: 30px;
  width: 220px;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > p {
  font-size: 1.2em;
  color: #666;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > button.box {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background-color: #FFCC00;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.opp.model > main.step5 > .requestProposalOpps > .desc {
  margin-top: 30px;
  font-size: 1.1em;
  color: #999;
}
@media screen and (max-width: 991px) {
  body > #root > section.opp.model > main.step5 {
    height: calc(100vh - 125px);
    /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 125px);
  }
  body > #root > section.opp.model > main.step5 > .requestProposalOpps {
    margin-bottom: 10px;
  }
}
