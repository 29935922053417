/* Font Family */
/* Borda arredondada */
body > #root section.rep > main {
  overflow-y: scroll;
}
body > #root section.rep > main header > .hover {
  cursor: help;
}
body > #root section.rep > main > .header > h1 {
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
body > #root section.rep > main > .header > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root section.rep > main > .header > div > h3 {
  width: auto;
  font-size: 1.1rem;
  color: #4d4d4d;
  margin-right: 10px;
}
body > #root section.rep > main > .header > div > select {
  padding: 8px 10px;
  color: #333;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root section.rep > main > .container > div.left,
body > #root section.rep > main > .container > div.center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 30%;
}
body > #root section.rep > main > .container > div.center {
  margin: 0 20px;
}
body > #root section.rep > main > .container > div.center > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  height: auto;
}
body > #root section.rep > main > .container > div.center > *:not(:first-child) {
  height: auto;
}
body > #root section.rep > main > .container > div.right {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 40%;
}
@media screen and (max-width: 991px) {
  body > #root section.rep > main {
    min-height: 100%;
  }
  body > #root section.rep > main > .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    height: auto!important;
  }
  body > #root section.rep > main > .header > h1 {
    max-width: 100%;
  }
  body > #root section.rep > main > .header > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    padding-top: 10px;
  }
  body > #root section.rep > main > .header > div > h3 {
    font-size: 1rem;
  }
  body > #root section.rep > main > .container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root section.rep > main > .container > .left,
  body > #root section.rep > main > .container > .center,
  body > #root section.rep > main > .container > .right {
    margin: 0 !important;
    width: 100% !important;
    min-width: initial !important;
  }
  body > #root section.rep > main > .container > .left > div,
  body > #root section.rep > main > .container > .center > div,
  body > #root section.rep > main > .container > .right > div {
    height: auto !important;
  }
  body > #root section.rep > main > .container > .center,
  body > #root section.rep > main > .container > .right {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root section.rep > main > .header > h1 {
    max-width: 55%;
  }
  body > #root section.rep > main > .header > div > h3 {
    font-size: 0.9rem;
  }
  body > #root section.rep > main > .container > div.left,
  body > #root section.rep > main > .container > div.center {
    min-width: 30%;
    max-width: 30%;
  }
  body > #root section.rep > main > .container > div.center {
    margin: 0 10px;
  }
}
body > .stepBox:not(.initial) {
  border-left: 8px solid #FBBC04;
}
body > .stepBox.initial {
  padding: 20px 30px 30px;
  text-align: center;
}
body > .stepBox.initial > .shepherd-content > .shepherd-text,
body > .stepBox.initial > .shepherd-content > .shepherd-footer {
  padding: 0;
}
body > .stepBox.initial > .shepherd-content > .shepherd-text {
  padding: 0 10px;
  font-size: 14px;
}
body > .stepBox[data-popper-placement="bottom"] {
  margin-top: 15px !important;
}
body > .stepBox[data-popper-placement="top"] {
  margin-bottom: 15px !important;
}
body > .stepBox[data-popper-placement="left"] {
  margin-right: 15px !important;
}
body > .stepBox[data-popper-placement="right"] {
  margin-left: 15px !important;
}
body > .stepBox > .shepherd-content > .shepherd-text {
  padding: 20px;
  font-size: 13px;
  line-height: 1.5em;
  color: #6B7280;
}
body > .stepBox > .shepherd-content > .shepherd-text > img.logo {
  margin-bottom: 10px;
  height: 56px;
}
body > .stepBox > .shepherd-content > .shepherd-text > h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #6B7280;
}
body > .stepBox > .shepherd-content > .shepherd-text > p {
  margin-bottom: 20px;
}
body > .stepBox > .shepherd-content > .shepherd-text > .warning {
  padding: 10px 15px;
  color: #6B7280;
  background-color: #FFF9C6;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > .stepBox > .shepherd-content > .shepherd-text > .info {
  padding: 10px 15px;
  color: #E4EBF5;
  background-color: #45565E;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > .stepBox > .shepherd-content > .shepherd-text > .stepCount {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 12px;
  font-weight: 600;
  color: #999;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button {
  font-weight: 600;
  background-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnInit {
  width: 100%;
  color: #FFF;
  background-color: #457EF6;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnInit:hover {
  background-color: #0b51e3;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnSkip {
  color: #9CA3AF;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnSkip:hover {
  color: #808998;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnNext {
  color: #003063;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnNext:hover {
  color: #001730;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnSuccess {
  color: #FFF;
  background-color: #197e19;
}
body > .stepBox > .shepherd-content > .shepherd-footer > button.btnSuccess:hover {
  background-color: #0d3e0d;
}
.__react_component_tooltip.infosBox {
  border: 1px solid #D0D0D0;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  font-size: 13px;
  line-height: 1.5em;
  color: #6B7280;
  background-color: #FFF;
  border-left: 8px solid #FBBC04;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  /* For IE8 and earlier */
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.__react_component_tooltip.infosBox.big {
  max-width: 500px;
}
.__react_component_tooltip.infosBox.place-bottom::after {
  top: -10px !important;
  margin-left: -13px !important;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom-width: 10px !important;
}
.__react_component_tooltip.infosBox.place-left::after {
  right: -10px !important;
  margin-top: -12px !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-left-width: 10px !important;
}
.__react_component_tooltip.infosBox.place-right::after {
  left: -10px !important;
  margin-top: -12px !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-right-width: 10px !important;
}
.__react_component_tooltip.infosBox > .warning {
  padding: 10px 15px;
  color: #6B7280;
  background-color: #FFF9C6;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.__react_component_tooltip.infosBox > .info {
  padding: 10px 15px;
  color: #E4EBF5;
  background-color: #45565E;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.__react_component_tooltip.infosBox > p {
  margin: 0;
}
.__react_component_tooltip.infosBox > p:not(:last-child) {
  margin-bottom: 20px;
}
.__react_component_tooltip.infosBox > p.notes {
  color: #a1a6b0;
}
.__react_component_tooltip.infosBox > ul {
  margin-bottom: 20px;
  padding: 0 0 0 15px;
}
.__react_component_tooltip.infosBox > ul > li {
  padding: 2px 0;
}
.__react_component_tooltip.infosBox > ul > li > a {
  color: #145cf4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.__react_component_tooltip.infosBox > ul > li > a:hover {
  color: #07379b;
}
.modal-tariffs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.modal-tariffs > span {
  color: #C0C0C0;
}
.modal-tariffs > p {
  line-height: 25px;
  color: #6B7280;
}
.modal-tariffs > .link {
  color: #457EF6;
  text-decoration: underline;
  margin: 3px 0px;
}
.modal-tariffs > .link:hover {
  color: #145cf4;
}
.modal-tariffs > button {
  margin-top: 25px;
  color: #6B7280;
  width: 100%;
}
.wizard_mobile {
  font-size: 1.1em;
  text-align: center;
}
.wizard_mobile > img {
  width: 80%;
}
.wizard_mobile > p > svg {
  font-size: 1.4em;
  color: #c0c0c0;
}
.wizard_mobile > button {
  width: 100%;
  background-color: #3288e6;
  border: 0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 0.5rem 1.5rem;
  color: #FFF;
}
