/* Font Family */
/* Borda arredondada */
/* background-color: #003063; */
body > #root > section > main .proposalBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section > main .proposalBox:not(:first-child) {
  margin-top: 20px;
}
body > #root > section > main .proposalBox > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 13px;
  cursor: help;
  background-color: #D0D0D0;
}
body > #root > section > main .proposalBox > .status.active {
  background-color: #22A822;
}
body > #root > section > main .proposalBox > .status.ended {
  background-color: #fb6704;
}
body > #root > section > main .proposalBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0 30px;
}
body > #root > section > main .proposalBox > .infos > .client,
body > #root > section > main .proposalBox > .infos > .type,
body > #root > section > main .proposalBox > .infos > .date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 130px;
}
body > #root > section > main .proposalBox > .infos > .client.type,
body > #root > section > main .proposalBox > .infos > .type.type,
body > #root > section > main .proposalBox > .infos > .date.type {
  width: 180px;
}
body > #root > section > main .proposalBox > .infos > .client > small,
body > #root > section > main .proposalBox > .infos > .type > small,
body > #root > section > main .proposalBox > .infos > .date > small {
  font-size: 0.6rem;
  font-weight: 600;
  color: #C0C0C0;
}
body > #root > section > main .proposalBox > .infos > .client > span,
body > #root > section > main .proposalBox > .infos > .type > span,
body > #root > section > main .proposalBox > .infos > .date > span {
  font-size: 0.95rem;
  font-weight: 500;
  color: #666;
}
body > #root > section > main .proposalBox > .infos > .client {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .proposalBox > .infos > .client > span {
  flex: 1;
  flex-basis: 0%;
  flex-basis: 0.000000001px;
  display: block;
  width: 90%;
  max-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -moz-box-flex: 1;
}
body > #root > section > main .proposalBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
}
body > #root > section > main .proposalBox > .buttons > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.75rem 1.2rem;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .proposalBox > .buttons > *.download {
  color: #c95203;
  background-color: #fee1cd;
  border-color: #fda468;
}
body > #root > section > main .proposalBox > .buttons > *:not(:disabled):hover.download {
  color: #b04803;
  background-color: #fdc29b;
  border-color: #fc8536;
}
body > #root > section > main .proposalBox > .buttons > *:disabled {
  cursor: default;
  color: #999;
  background-color: #F0F0F0;
  border-color: #C0C0C0;
}
body > #root > section > main .proposalBox > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
