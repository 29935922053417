/* Font Family */
/* Borda arredondada */
body > #root .left > .tariff {
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #C0C0C0;
}
body > #root .left > .tariff .title {
  font-size: 16px;
  color: #263238;
  font-weight: 400;
}
body > #root .left > .tariff .body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body > #root .left > .tariff .body label {
  font-size: 24px;
  font-weight: 700;
}
body > #root .left > .tariff .body small {
  font-size: 14px;
  color: #6B7280;
  font-weight: 500;
}
body > #root .left > .tariff .body p {
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 10px;
}
body > #root .left > .tariff .body div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
body > #root .left > .tariff .body span {
  text-align: center;
  font-size: 14px;
  color: #DF0D0D;
  text-decoration: underline #DF0D0D;
  cursor: pointer;
}
body > #root .left > .tariff.corr .body label {
  color: #10B981;
}
body > #root .left > .tariff.corr .body p {
  color: #065F46;
  background-color: #D1FAE5;
}
body > #root .left > .tariff.err .body label {
  color: #DF0D0D;
}
body > #root .left > .tariff.err .body p {
  color: #991B1B;
  background-color: #FEE2E2;
}
body > #root .left > .tariff.err-corr {
  background-color: #FEE2E2;
  border-top: dashed 3px #DF0D0D;
  border-bottom: dashed 3px #DF0D0D;
}
body > #root .left > .tariff.err-corr .title {
  color: #991B1B;
}
body > #root .left > .tariff.err-corr .body label {
  color: #991B1B;
  margin-top: 10px;
}
body > #root .left > .tariff.err-corr .body small {
  color: #991B1B;
}
body > #root .left > .tariff.err-corr .body p {
  display: none;
}
body > #root .left > .tariff:last-child {
  border: 0px;
}
body > #root .left > .tariff:last-child.err-corr {
  border-top: dashed 3px #DF0D0D;
  border-bottom: dashed 3px #DF0D0D;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
