/* Font Family */
/* Borda arredondada */
body > #root > .pageError {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background: url('../../assets/images/bkg_404.jpg') #FFF;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
body > #root > .pageError > h1 {
  margin: 0 0 15px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 15em;
  line-height: 1em;
}
body > #root > .pageError > h1 > span {
  margin: 0 10px;
  font-weight: 300;
  color: #192A56;
}
body > #root > .pageError > h1 > span:nth-child(1) {
  font-weight: bold;
  color: #717FA3;
}
body > #root > .pageError > h1 > span:nth-child(3) {
  font-weight: bolder;
  color: #192A56;
}
body > #root > .pageError > h2 {
  margin: 0 0 60px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 2.8em;
  color: #04184C;
}
body > #root > .pageError > .btnHome {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #666;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > .pageError > .btnHome > span {
  margin-left: 5px;
}
body > #root > .pageError > .btnHome:hover {
  color: #333;
}
@media screen and (max-width: 767px) {
  body > #root > .pageError > h1 {
    font-size: 10em;
  }
  body > #root > .pageError > h2 {
    font-size: 2em;
  }
}
