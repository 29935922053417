/* Font Family */
/* Borda arredondada */
body > #root > section > main .historicBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section > main .historicBox:not(:first-child) {
  margin-top: 20px;
}
body > #root > section > main .historicBox > .icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 50px;
}
body > #root > section > main .historicBox > .icon > img {
  height: 80%;
}
body > #root > section > main .historicBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 0 30px;
}
body > #root > section > main .historicBox > .infos > .provider,
body > #root > section > main .historicBox > .infos > .modality,
body > #root > section > main .historicBox > .infos > .date {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 120px;
}
body > #root > section > main .historicBox > .infos > .provider > small,
body > #root > section > main .historicBox > .infos > .modality > small,
body > #root > section > main .historicBox > .infos > .date > small {
  font-size: 0.6rem;
  font-weight: 600;
  color: #C0C0C0;
}
body > #root > section > main .historicBox > .infos > .provider > span,
body > #root > section > main .historicBox > .infos > .modality > span,
body > #root > section > main .historicBox > .infos > .date > span {
  font-size: 0.95rem;
  font-weight: 500;
  color: #666;
}
body > #root > section > main .historicBox > .infos > .provider {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .historicBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
}
body > #root > section > main .historicBox > .buttons > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.50rem 0.75rem;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .historicBox > .buttons > *.download {
  padding-left: 2rem;
  padding-right: 2rem;
  color: #c95203;
  background-color: #fee1cd;
  border-color: #fda468;
}
body > #root > section > main .historicBox > .buttons > *.manual {
  width: 100.66px;
  color: #999;
  cursor: default;
}
body > #root > section > main .historicBox > .buttons > *.manual > sup {
  margin-right: 5px;
}
body > #root > section > main .historicBox > .buttons > *.download,
body > #root > section > main .historicBox > .buttons > *.manual:not(.short) {
  width: 145px;
}
body > #root > section > main .historicBox > .buttons > *.edit {
  margin-left: 5px;
  color: #1e931e;
  border-color: #197e19;
}
body > #root > section > main .historicBox > .buttons > *.del {
  margin-left: 5px;
}
body > #root > section > main .historicBox > .buttons > *.del:not(:disabled) {
  color: #ec584c;
  border-color: #ef6e63;
}
body > #root > section > main .historicBox > .buttons > *.del:disabled {
  color: #cdcdcd;
  border-color: #dddddd;
}
body > #root > section > main .historicBox > .buttons > *.check {
  margin-left: 5px;
  cursor: help;
}
body > #root > section > main .historicBox > .buttons > *.check:not(:disabled) {
  color: #1e931e;
  border-color: #197e19;
}
body > #root > section > main .historicBox > .buttons > *.check:disabled {
  color: #cdcdcd;
  border-color: #dddddd;
}
body > #root > section > main .historicBox > .buttons > *:not(:disabled):hover.download {
  color: #b04803;
  background-color: #fdc29b;
  border-color: #fc8536;
}
body > #root > section > main .historicBox > .buttons > *:not(:disabled):hover.edit {
  color: #156815;
  border-color: #197e19;
}
body > #root > section > main .historicBox > .buttons > *:not(:disabled):hover.del {
  color: #d62516;
  border-color: #e82e1e;
}
body > #root > section > main .historicBox > .buttons > #signed {
  position: absolute;
  top: calc(115%) !important;
  left: auto !important;
  right: 0 !important;
  width: max-content;
  color: #FFF;
  background-color: #156815;
  border-color: #115311;
}
body > #root > section > main .historicBox > .buttons > #signed:before,
body > #root > section > main .historicBox > .buttons > #signed:after {
  display: none !important;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .historicBox > .icon {
    display: none !important;
  }
  body > #root > section > main .historicBox > .infos {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin: 0;
  }
  body > #root > section > main .historicBox > .infos > .date,
  body > #root > section > main .historicBox > .infos > .provider,
  body > #root > section > main .historicBox > .infos .modality {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin-right: 10px;
    width: auto;
  }
  body > #root > section > main .historicBox > .infos > .date > small,
  body > #root > section > main .historicBox > .infos > .provider > small,
  body > #root > section > main .historicBox > .infos .modality > small {
    font-size: 0.55rem;
  }
  body > #root > section > main .historicBox > .infos > .date > span,
  body > #root > section > main .historicBox > .infos > .provider > span,
  body > #root > section > main .historicBox > .infos .modality > span {
    font-size: 0.8rem;
  }
  body > #root > section > main .historicBox > .infos > .provider {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section > main .historicBox > .buttons {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section > main .historicBox > .buttons > * {
    padding: 0.4rem 0.7rem;
  }
  body > #root > section > main .historicBox > .buttons > *.download {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    font-size: 0.7rem;
  }
}
.dark > body > #root > section > main .historicBox {
  background-color: #4d4d4d;
  border-color: #808080;
}
.dark > body > #root > section > main .historicBox .infos > .date > label {
  color: #999;
}
.dark > body > #root > section > main .historicBox .infos > .date > span {
  color: #D0D0D0;
}
.dark > body > #root > section > main .historicBox .buttons > * {
  border-color: #999;
}
.dark > body > #root > section > main .historicBox .buttons > *.download {
  color: #C0C0C0;
}
.dark > body > #root > section > main .historicBox .buttons > *.del {
  color: #f1837a;
  border-color: #f49991;
}
.dark > body > #root > section > main .historicBox .buttons > *:hover.download {
  color: #F0F0F0;
  border-color: #D0D0D0;
}
.dark > body > #root > section > main .historicBox .buttons > *:hover.del {
  color: #f6aea8;
  border-color: #f8c4c0;
}
