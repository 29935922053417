/* Font Family */
/* Borda arredondada */
body > #root > section > main .chartsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px 20px 10px;
  background-color: #FFF;
  border: solid 2px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section > main .chartsBox > .content > div > header,
body > #root > section > main .chartsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
}
body > #root > section > main .chartsBox > .content > div > header > .titles > h3,
body > #root > section > main .chartsBox > header > .titles > h3 {
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #4d4d4d;
}
body > #root > section > main .chartsBox > .content > div > header > .titles > h4,
body > #root > section > main .chartsBox > header > .titles > h4 {
  font-size: 0.9rem;
  font-weight: 400;
  color: #999;
}
body > #root > section > main .chartsBox > .content > div > header > .hover,
body > #root > section > main .chartsBox > header > .hover {
  margin-top: 2px;
  margin-left: 30px;
  font-size: 1.1rem;
  color: #a7a7a7;
}
body > #root > section > main .chartsBox > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 30px 0;
}
body > #root > section > main .chartsBox > .content > div > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > svg {
  min-height: 250px !important;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper {
  outline: none;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart {
  padding: 15px;
  background-color: #FFF;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > h4 {
  margin: 0 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > h5 {
  margin: -10px 0 10px;
  font-size: 0.7rem;
  font-weight: 400;
  color: #999;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > h5 > b {
  font-weight: 600;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 5px 0 0;
  font-size: 0.8rem;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > div > b {
  margin-right: 15px;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > div.alert {
  color: #EA4335;
  font-weight: 600;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-tooltip-wrapper > .tooltipChart > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 10px 0;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  bottom: -10px !important;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div,
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0;
  padding: 15px 0 0 !important;
  font-size: 12px;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div:not(:first-child),
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div:not(:first-child) {
  margin-left: 20px;
}
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > div > .color,
body > #root > section > main .chartsBox > .content > div > .content > .recharts-responsive-container > .recharts-wrapper > .recharts-legend-wrapper > .box > .line > div > .color {
  margin-right: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section > main .chartsBox > footer > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .chartsBox > footer > .line:not(:first-child) {
  margin-top: 10px;
}
body > #root > section > main .chartsBox > footer > .line > span {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 12px;
  color: #4d4d4d;
}
body > #root > section > main .chartsBox > footer > .line > span.ball {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  background-color: #003063;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .chartsBox > .content > div > header,
  body > #root > section > main .chartsBox > .content > header {
    margin-bottom: 15px;
  }
  body > #root > section > main .chartsBox > .content > div > header > .titles > h3,
  body > #root > section > main .chartsBox > .content > header > .titles > h3 {
    margin-bottom: 3px;
    font-size: 1rem;
  }
  body > #root > section > main .chartsBox > .content > div > header > .titles > h4,
  body > #root > section > main .chartsBox > .content > header > .titles > h4 {
    font-size: 0.8rem;
  }
  body > #root > section > main .chartsBox > .content > div > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section > main .chartsBox > .content > div > .content > .recharts-wrapper > .recharts-legend-wrapper {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section > main .chartsBox > .content > div > .content > .recharts-wrapper > .recharts-legend-wrapper > div {
    padding-bottom: 15px;
  }
  body > #root > section > main .chartsBox > .content > div > .content > .legends {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section > main .chartsBox > .content > div > .content > .legends > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 0;
    padding: 15px 0 0 !important;
    font-size: 12px;
  }
  body > #root > section > main .chartsBox > .content > div > .content > .legends > div > .color {
    margin-right: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section > main .chartsBox > .content > div > header > .titles > h3,
  body > #root > section > main .chartsBox > header > .titles > h3 {
    margin-bottom: 3px;
    font-size: 0.9rem;
  }
  body > #root > section > main .chartsBox > .content > div > header > .titles > h4,
  body > #root > section > main .chartsBox > header > .titles > h4 {
    font-size: 0.75rem;
  }
  body > #root > section > main .chartsBox > .content .recharts-responsive-container {
    position: relative;
    width: 100% !important;
  }
  body > #root > section > main .chartsBox > .content .recharts-responsive-container > .recharts-wrapper {
    width: 100% !important;
  }
}
