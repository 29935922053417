/* Font Family */
/* Borda arredondada */
body > #root > section > main .monthlyReport {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: solid 2px #D0D0D0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section > main .monthlyReport * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > section > main .monthlyReport > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px 10px;
}
body > #root > section > main .monthlyReport > header > .titles {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 60%;
}
body > #root > section > main .monthlyReport > header > .titles > h3 {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #4d4d4d;
}
body > #root > section > main .monthlyReport > header > .titles > h4 {
  margin-top: 10px;
  margin-bottom: 24px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #607D8B;
}
body > #root > section > main .monthlyReport > header > .hover {
  margin-top: 2px;
  margin-left: 30px;
  font-size: 1.1rem;
  color: #a7a7a7;
}
body > #root > section > main .monthlyReport > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .monthlyReport > .content > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 0 1.2rem 0px;
  margin-bottom: 20px;
}
body > #root > section > main .monthlyReport > .content > .line.head {
  margin-top: -5px;
}
body > #root > section > main .monthlyReport > .content > .line.head > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  margin-right: 20px;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span {
  font-size: 0.9rem;
  color: #999;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span.number {
  font-size: 2.8rem;
  font-weight: 600;
  color: #666;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.left > img {
  padding-bottom: 8px;
  width: 110px;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  padding-bottom: 8px;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 8px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  text-align: center;
  color: #999;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select {
  width: 80%;
  position: relative;
  border: 1.5px solid #dddddd;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > label {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 5px;
  font-size: 0.5rem;
  background-color: #dddddd;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  padding: 5px 4px 5px 10px;
  font-size: 0.6rem;
  color: #666;
  cursor: pointer;
  background-color: #FFF;
  border: none;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button > svg {
  margin-left: 5px;
  padding-bottom: 1px;
  font-size: 0.8rem;
  color: #D0D0D0;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > .selectBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > .selectBox > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  text-align: center;
  padding: 5px;
  margin: 0;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  outline: none;
  background-color: transparent;
  border: none;
  z-index: 1;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > .selectBox > select::-ms-expand {
  display: none;
}
body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > svg {
  margin: 5px 0;
  font-size: 0.7rem;
  color: #C0C0C0;
}
body > #root > section > main .monthlyReport > .content .content_box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 1.2rem 0px;
}
body > #root > section > main .monthlyReport > .content .content_box > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 15px;
  background-color: #f6f6f6;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section > main .monthlyReport > .content .content_box > .infos > p {
  margin: 4px 0;
  padding: 0;
  font-size: 1em;
  color: #666;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > h5 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 25px 0 10px;
  padding-bottom: 8px;
  font-size: 0.9rem;
  color: #999;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line:not(:first-child) {
  margin-top: 10px;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > div {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line .detail {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 10px;
  margin-left: 10px;
  height: auto;
  font-size: 0.7rem;
  cursor: pointer;
  border: 1px solid #c3c3c3;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line .detail:hover {
  border: 1px solid #aaaaaa;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 12px;
  width: 40%;
  height: 56px;
  font-size: 0.8rem;
  color: #666;
  border: 1px dashed #c3c3c3;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .name > span {
  font-weight: 500;
  color: #666;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .perc {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-left: 10px;
  padding: 12px;
  width: 30%;
  font-size: 1em;
  font-weight: 600;
  color: #666;
  background-color: #F0F0F0;
  white-space: nowrap;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .perc.green {
  border: solid 1px #10B981;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .perc.red {
  border: solid 1px #EA4335;
}
body > #root > section > main .monthlyReport > .content .content_box > .upPeriod > .line > .perc.blue {
  border: solid 1px #457EF6;
}
body > #root > section > main .monthlyReport > .content .content_box > .notes {
  padding-bottom: 30px;
  margin-top: 20px;
  font-size: 0.8rem;
  font-style: italic;
  color: #333;
}
body > #root > section > main .monthlyReport > .alert {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding-bottom: 80px;
}
body > #root > section > main .monthlyReport > .alert > p,
body > #root > section > main .monthlyReport > .alert > a {
  color: #EA4335;
  font-size: 15px;
}
body > #root > section > main .monthlyReport > .alert a {
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .monthlyReport > header > .titles {
    width: 100%;
  }
  body > #root > section > main .monthlyReport > header > .titles > h3 {
    margin-bottom: 0;
    font-size: 1rem;
  }
  body > #root > section > main .monthlyReport > header > .titles > h4 {
    font-size: 0.8rem;
    margin-bottom: 25px;
  }
  body > #root > section > main .monthlyReport > .content > .line {
    margin-bottom: 10px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span {
    font-size: 0.9rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span.number {
    font-size: 2.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > img {
    width: 90px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > span {
    margin-bottom: 8px;
    font-size: 0.7rem;
    line-height: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > label {
    padding: 2px 4px;
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button {
    padding: 5px 4px 5px 10px;
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button > svg {
    margin-left: 5px;
    font-size: 0.65rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > .selectBox > select {
    font-size: 0.7rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > svg {
    margin: 4px 0;
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .infos {
    padding: 8px 10px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .infos > p {
    margin: 3px 0;
    font-size: 0.65rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > h5 {
    margin: 25px 0 5px;
    font-size: 0.8rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line:not(:first-child) {
    margin-top: 6px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .name {
    padding: 10px;
    font-size: 0.8rem;
    height: 53px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .detail {
    width: auto;
    padding: 10px;
    font-size: 0.7rem;
    height: 53px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .perc {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-left: 8px;
    padding: 6px;
    width: 30% !important;
    max-width: 30% !important;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
    background-color: #F0F0F0;
  }
  body > #root > section > main .monthlyReport > .content > .more {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
    padding: 10px;
    width: 100%;
    font-size: 0.7rem;
    color: #00509D;
    cursor: pointer;
    border-top: 2px solid #D0D0D0;
  }
  body > #root > section > main .monthlyReport > .loading {
    margin: 0;
    padding: 30px;
  }
  body > #root > section > main .monthlyReport > .alert {
    padding: 30px 20px 40px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section > main .monthlyReport > header > .titles {
    width: 80%;
  }
  body > #root > section > main .monthlyReport > header > .titles > h3 {
    font-size: 1em;
  }
  body > #root > section > main .monthlyReport > header > .titles > h4 {
    font-size: 0.8em;
    margin-bottom: 20px;
  }
  body > #root > section > main .monthlyReport > .content > .line {
    margin-bottom: 10px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown {
    margin-right: 10px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span {
    font-size: 0.8rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > .upDown > span.number {
    font-size: 2.2rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.left > img {
    width: 60px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
    padding-bottom: 6px;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > span {
    margin-bottom: 8px;
    font-size: 0.6rem;
    line-height: 0.7rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > label {
    padding: 3px 5px;
    font-size: 0.5rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button {
    padding: 5px 4px 5px 10px;
    font-size: 0.5rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > button > svg {
    margin-left: 5px;
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > .select > .selectBox > select {
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .line.head > div.right > .boxs > svg {
    margin: 4px 0;
    font-size: 0.6rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .infos {
    margin-top: 10px;
    padding: 12px 10px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .infos > p {
    margin: 3px 0;
    font-size: 0.8em;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > h5 {
    margin: 20px 0 5px;
    font-size: 0.7rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line:not(:first-child) {
    margin-top: 8px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .name {
    padding: 10px;
    font-size: 0.7rem;
    height: 55px;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .name > small {
    margin-top: 2px;
    font-size: 0.53rem;
  }
  body > #root > section > main .monthlyReport > .content > .content_box > .upPeriod > .line > .perc {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-left: 8px;
    padding: 10px;
    width: 30%;
    height: 100%;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
    background-color: #F0F0F0;
  }
}
.modalComparative > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.modalComparative > div > svg {
  color: #6B7280;
  font-size: 1.5em;
}
.modalComparative > div > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 5px;
  margin: 10px;
  background-color: #F8FAFC;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.modalComparative > div > div.up {
  border: 1px solid red;
}
.modalComparative > div > div.down {
  border: 1px solid green;
}
.modalComparative > div > div.equal {
  border: 1px solid #457EF6;
}
.modalComparative > div > div img {
  width: 35px;
  margin-left: 10px;
}
.modalComparative > div > div label {
  color: #374151;
  font-size: 1.2em;
  margin-bottom: 5px;
}
.modalComparative > div > div span {
  color: #6B7280;
  font-size: 1.8em;
  font-weight: 500;
}
.modalComparative > h1 {
  font-size: 1.4em;
  margin-top: 10px;
}
.modalComparative > h2 {
  font-size: 1.1em;
  font-weight: 400;
  color: #6B7280;
  margin-bottom: 35px;
}
.modalComparative > p {
  font-size: 1.1em;
  margin: 3px 0px;
  color: #6B7280;
}
@media screen and (max-width: 991px) {
  .modalComparative > div > svg {
    font-size: 0.8em;
  }
  .modalComparative > div > div {
    width: 40%;
    padding: 10px 5px;
    margin: 5px;
  }
  .modalComparative > div > div > div {
    display: flex;
    flex-direction: row;
  }
  .modalComparative > div > div img {
    width: 18px;
    margin-left: 8px;
  }
  .modalComparative > div > div label {
    font-size: 1em;
    margin-bottom: 5px;
  }
  .modalComparative > div > div span {
    font-size: 1.1em;
    font-weight: 800;
  }
}
