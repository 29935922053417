/* Font Family */
/* Borda arredondada */
body > #root > #modalBox > .modalBody > .modalContent > .planLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 10px;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  margin-bottom: 20px;
}
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel {
  margin-bottom: 15px;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel,
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel:not(:first-child),
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel:not(:first-child) {
  margin-left: 10px;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel > label,
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel > label {
  font-size: 0.85em;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel > input,
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel > input,
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel > select,
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 100%;
  background-color: #FFF;
}
body > #root > #modalBox > .modalBody > .modalContent > .planLine > .planGroup > .inputLabel > input,
body > #root > #modalBox > .modalBody > .modalContent > .inputLabel > input {
  padding: 10px 6px;
}
body > #root > #modalBox > .modalBody > .modalContent > button {
  padding: 10px;
  font-size: 1em;
  color: #FFF !important;
}
