/* Font Family */
/* Borda arredondada */
body > #root > section.opp.model > main.step5 > .plansOpps {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: auto;
  width: 60%;
  overflow-y: auto;
}
body > #root > section.opp.model > main.step5 > .plansOpps > h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1.4em;
  color: #333;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans {
  width: 45%;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 12px;
  width: 100%;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox:not(:first-child) {
  margin-top: 15px;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 15px 0 0;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .select > span {
  position: relative;
  width: 15px;
  height: 15px;
  border: 2px solid #666;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .infos > .title {
  margin-bottom: 5px;
  font-size: 1.2em;
  font-weight: 600;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .infos > .year,
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .infos > .economy {
  margin-top: 3px;
  font-size: 1em;
  line-height: 1.3em;
  color: #333;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox > .icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 50px;
  filter: grayscale(1);
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox.active {
  border-color: #e6b800;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox.active > .select > span:before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: #006ee3;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > .planBox.active > .icon {
  filter: grayscale(0);
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans > button.box {
  margin-top: 15px;
  padding: 10px 15px;
  width: 100%;
  font-weight: 600;
  color: #000;
  background-color: #FFCC00;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .conditions {
  width: 50%;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .conditions > h4 {
  margin-bottom: 25px;
  font-size: 1.3em;
  color: #808080;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .conditions > .conditionsBox:not(:first-child) {
  margin-top: 15px;
}
body > #root > section.opp.model > main.step5 > .plansOpps > hr {
  margin: 50px 0;
  width: 100%;
  height: 1px;
  background-color: #C0C0C0;
  border: none;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .terms > h5 {
  margin: 0 0 20px;
  font-size: 1.3em;
  color: #666;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .terms > .infos {
  font-size: 1em;
  line-height: 1.8em;
  color: #666;
}
body > #root > section.opp.model > main.step5 > .plansOpps > .terms > .infos > p {
  margin: 20px 0;
}
@media screen and (max-width: 991px) {
  body > #root > section.opp.model > main.step5 {
    height: calc(100vh - 125px);
    /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 125px);
    overflow: auto;
  }
  body > #root > section.opp.model > main.step5 > .plansOpps {
    width: 100%;
  }
  body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .plans,
  body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .conditions {
    width: 100%;
  }
  body > #root > section.opp.model > main.step5 > .plansOpps > .lineBox > .conditions {
    margin-top: 40px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section.opp.model > main.step5 > .plansOpps {
    width: 80%;
  }
  body > #root > section.opp.model > main.step5 > .plansOpps > hr {
    margin: 40px 0;
  }
}
