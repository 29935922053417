/* Font Family */
/* Borda arredondada */
.inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.inputLabel > label {
  padding-bottom: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #666;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.inputLabel > input,
.inputLabel > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 15px;
  color: #999;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition-property: border-color, color;
  -moz-transition-property: border-color, color;
  -o-transition-property: border-color, color;
  -ms-transition-property: border-color, color;
  transition-property: border-color, color;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  /* Change the white to any color */
}
.inputLabel > input:focus,
.inputLabel > select:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
}
.inputLabel > input:-webkit-autofill,
.inputLabel > select:-webkit-autofill,
.inputLabel > input:-webkit-autofill:hover,
.inputLabel > select:-webkit-autofill:hover,
.inputLabel > input:-webkit-autofill:focus,
.inputLabel > select:-webkit-autofill:focus,
.inputLabel > input:-webkit-autofill:active,
.inputLabel > select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
}
.inputLabel.hasError > input,
.inputLabel.hasError > select {
  border-color: #f6aea8 !important;
}
.inputLabel.hasError > small {
  margin-top: 1px;
  font-size: 0.7rem;
  font-style: italic;
  color: #EA4335;
}
