/* Font Family */
/* Borda arredondada */
body > #root > #modalBox {
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(102, 102, 102, 0.9);
  z-index: 999;
}
body > #root > #modalBox .modalBody {
  margin: 0 auto;
  max-width: 500px;
  background-color: #FFF;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
body > #root > #modalBox .modalBody > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  border-bottom: 1px solid #F0F0F0;
}
body > #root > #modalBox .modalBody > header > h3 {
  padding: 20px;
  font-size: 1.3rem;
}
body > #root > #modalBox .modalBody > header > button.close {
  padding: 20px;
  border: none;
}
body > #root > #modalBox .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 20px 20px;
}
body > #root > #modalBox.enz {
  position: fixed;
  top: 0;
  left: 0;
  padding: 50px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(102, 102, 102, 0.9);
  z-index: 999;
}
body > #root > #modalBox.enz .modalBody {
  margin: 0 auto;
  max-width: 500px;
  max-height: 100%;
  overflow: hidden;
  background-color: #FFF;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
body > #root > #modalBox.enz .modalBody.delete > header {
  background-color: #79150d;
}
body > #root > #modalBox.enz .modalBody > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  color: #FFF;
  background-color: #003063;
  border-bottom: 1px solid #F0F0F0;
}
body > #root > #modalBox.enz .modalBody > header > h3 {
  margin: 0;
  padding: 20px;
  font-size: 1.3rem;
}
body > #root > #modalBox.enz .modalBody > header > button.close {
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.enz .modalBody > header > button.close:hover {
  color: rgba(255, 255, 255, 0.9);
}
body > #root > #modalBox.enz .modalBody > .modalContent {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px;
  max-height: 80vh;
  overflow: auto;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form > .form-group:not(:first-child),
body > #root > #modalBox.enz .modalBody > .modalContent > form > .input-group:not(:first-child) {
  margin-top: 20px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form > .form-group.no-margin,
body > #root > #modalBox.enz .modalBody > .modalContent > form > .input-group.no-margin {
  margin: 0 !important;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group > label,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group > label {
  font-size: 0.8em;
  font-weight: 600;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group > label > i,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group > label > i {
  font-size: 0.75em;
  color: #999;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input.upC,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input.upC,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select.upC,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select.upC {
  text-transform: uppercase;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input.error,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input.error,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select.error,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select.error {
  background-color: #fdefee;
  border: 1px solid #d62516;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input.error:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input.error:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select.error:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select.error:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input.error:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input.error:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select.error:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select.error:focus {
  background-color: #fef8f7;
  border-color: #a81d11;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select:hover,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group input:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group input:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group select:focus,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group select:focus {
  border-color: #333;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w50,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w50 {
  width: 50px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w100,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w100 {
  width: 100px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w140,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w140 {
  width: 140px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w150,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w150 {
  width: 150px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w200,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w200 {
  width: 200px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w220,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w220 {
  width: 220px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w250,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w250 {
  width: 250px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w260,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w260 {
  width: 260px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w300,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w300 {
  width: 300px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w350,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w350 {
  width: 350px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w400,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w400 {
  width: 400px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w450,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w450 {
  width: 450px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w500,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w500 {
  width: 500px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.w550,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.w550 {
  width: 550px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form .form-group > .input-group.mg-none,
body > #root > #modalBox.enz .modalBody > .modalContent > form .input-group.mg-none {
  margin: 0;
}
body > #root > #modalBox.enz .modalBody > .modalContent > form > .form-group > .input-group:not(:first-child) {
  margin-left: 15px;
}
body > #root > #modalBox.enz .modalBody > .modalContent > .alert {
  margin-top: 15px;
  font-size: 0.8em;
  font-weight: bold;
  color: #cc0000;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin: 30px -15px -15px;
  padding: 15px;
  background-color: #e3e3e3;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button {
  padding: 10px 15px;
  color: #FFF;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnSave {
  background-color: #197e19;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnSave:hover {
  background-color: #115311;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnDelete {
  background-color: #d62516;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnDelete:hover {
  background-color: #a81d11;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnCancel {
  background-color: #8c8c8c;
}
body > #root > #modalBox.enz .modalBody > .modalContent > footer > button#btnCancel:hover {
  background-color: #737373;
}
@media screen and (max-width: 991px) {
  body > #root > #modalBox {
    padding: 25px;
  }
}
