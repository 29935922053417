/* Font Family */
/* Borda arredondada */
body > #root #step-create {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
}
body > #root #step-create > div {
  width: 400px;
}
body > #root #step-create > div > div {
  margin: 15px 0px;
}
body > #root #step-create > div .mask svg {
  padding: 2px;
  margin-left: auto;
  margin-top: -30px;
  margin-right: 10px;
}
body > #root #step-create > div .mask svg.loading {
  color: #a2a22b;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
}
body > #root #step-create > div .mask svg.error {
  color: #EA4335;
}
body > #root #step-create > div .mask svg.success {
  color: #22A822;
}
body > #root #step-create > div .mask > small {
  margin-top: 6px;
}
body > #root #step-create > div .buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 10px 0px;
}
body > #root #step-create > div .buttons a,
body > #root #step-create > div .buttons button {
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  background-color: #003063;
  color: #FFF;
}
body > #root #step-create > div .buttons .back {
  background-color: #FFF;
  border: solid 1px #C0C0C0;
  color: #666;
}
@media screen and (max-width: 991px) {
  body > #root #step-create {
    width: 100vw;
  }
  body > #root #step-create > div {
    padding: 12px;
    width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
}
body > #root #terms {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: auto;
}
body > #root #terms > section {
  width: 70vw;
}
body > #root #terms > section .header {
  margin-top: 50px;
}
body > #root #terms > section .header img {
  height: 80px;
}
body > #root #terms > section .header h2 {
  color: #003063;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
}
body > #root #terms > section .header h3 {
  color: #374151;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 30px;
}
body > #root #terms > section .header h3 small {
  font-weight: bold;
  color: #003063;
  font-size: 16px;
}
body > #root #terms > section p {
  font-size: 16px;
  margin-bottom: 25px;
  color: #374151;
}
body > #root #terms > section label {
  font-size: 16px;
  margin-left: 10px;
  color: #374151;
}
body > #root #terms > section .checkbox {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
body > #root #terms > section .buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 50px 10px;
}
body > #root #terms > section .buttons button {
  padding: 10px 50px;
  border-radius: 10px;
  border: none;
  background-color: #003063;
  color: #FFF;
}
body > #root #terms > section .buttons .back {
  background-color: #FFF;
  border: solid 1px #C0C0C0;
  color: #666;
}
body > #root #confirm-mail {
  width: 500px;
  text-align: center;
  padding: 30px;
}
body > #root #confirm-mail div {
  padding: 30px;
  border: 1px solid #D0D0D0;
}
body > #root #confirm-mail h2 {
  font-size: 20px;
  color: #003063;
}
body > #root #confirm-mail p {
  font-size: 16px;
  color: #374151;
}
body > #root #confirm-mail .symbol {
  margin: 0px;
  color: #E8EAED;
  font-size: 80px;
  font-weight: 900;
}
body > #root #confirm-mail > a {
  text-decoration: underline;
  color: #003063;
  cursor: pointer;
  padding-top: 15px;
}
