/* Font Family */
/* Borda arredondada */
body > #root > main.analisy {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > main.analisy > #confirm-mail-contact {
  width: 500px;
  text-align: center;
  padding: 30px;
}
body > #root > main.analisy > #confirm-mail-contact div {
  padding: 30px;
  border: 1px solid #D0D0D0;
}
body > #root > main.analisy > #confirm-mail-contact h2 {
  font-size: 20px;
  color: #003063;
}
body > #root > main.analisy > #confirm-mail-contact p {
  font-size: 16px;
  color: #374151;
}
body > #root > main.analisy > #confirm-mail-contact .symbol {
  margin: 0px;
  color: #E8EAED;
  font-size: 80px;
  font-weight: 900;
}
