/* Font Family */
/* Borda arredondada */
body > #root > section.opp > main {
  overflow: auto;
}
body > #root > section.opp > main > .header > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.opp > main > .header > div > h3 {
  width: auto;
  font-size: 1rem;
  color: #4d4d4d;
  margin-right: 10px;
}
body > #root > section.opp > main > .header > div > select {
  padding: 8px 10px;
  color: #333;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > section.opp > main > .headLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 0 40px;
}
body > #root > section.opp > main > .headLine > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1.2rem;
  background-color: #FFF;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.opp > main > .headLine > *.eco {
  margin-left: 20px;
}
body > #root > section.opp > main > .headLine > *.eco > .info {
  font-size: 2.1rem;
  line-height: 2.7rem;
}
body > #root > section.opp > main > .headLine > * > .info {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: #333;
}
body > #root > section.opp > main > .headLine > * > .info > span.lab {
  margin-right: 10px;
  font-size: 1rem;
  color: #666;
}
body > #root > section.opp > main > .headLine > * > .info > span.number {
  font-size: 2.1rem;
}
body > #root > section.opp > main > .headLine > * > .label {
  margin-top: 3px;
  font-size: 0.75rem;
  color: #999;
}
body > #root > section.opp > main > #container-ops {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
body > #root > section.opp > main > #container-ops .rounded {
  position: relative;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: rgba(4, 49, 96, 0.5);
  z-index: 1;
}
body > #root > section.opp > main > #container-ops .rounded > p {
  font-size: 0.9em;
}
body > #root > section.opp > main > #container-ops .rounded > div {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5em;
}
body > #root > section.opp > main > #container-ops .title {
  font-size: 1.2em;
  font-weight: bold;
}
body > #root > section.opp > main > #container-ops > div {
  width: 50%;
  margin: 0px 25px;
  color: #FFF;
}
body > #root > section.opp > main > #container-ops > div.free-market {
  position: relative;
  padding: 20px;
  width: 432px;
  overflow: hidden;
  background-color: rgba(0, 74, 153, 0.5);
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
}
body > #root > section.opp > main > #container-ops > div.free-market > .bkg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('../../../assets/images/backgrounds/group278.png');
  filter: blur(80px);
  z-index: 0;
  opacity: 0.6;
  filter: alpha(opacity=60);
  /* For IE8 and earlier */
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
body > #root > section.opp > main > #container-ops > div.free-market > .content {
  position: relative;
  padding: 20px 20px;
  z-index: 1;
}
body > #root > section.opp > main > #container-ops > div.free-market > .content > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 15px;
}
body > #root > section.opp > main > #container-ops > div.free-market > .content > div > label {
  font-size: 0.9em;
}
body > #root > section.opp > main > #container-ops > div.free-market > .content > div.month > .value {
  font-size: 2em;
  font-weight: bold;
}
body > #root > section.opp > main > #container-ops > div.free-market > .content > div.year > .value {
  font-size: 1.7em;
  font-weight: bold;
}
body > #root > section.opp > main > #container-ops > div.free-market > .eco {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  z-index: 1;
}
body > #root > section.opp > main > #container-ops > div.free-market > .eco > .title {
  font-weight: 400;
}
body > #root > section.opp > main > #container-ops > div.free-market > .eco > label {
  font-weight: bold;
  font-size: 2.1em;
}
body > #root > section.opp > main > #container-ops > div.free-market > .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  z-index: 1;
}
body > #root > section.opp > main > #container-ops > div.free-market > .links a {
  margin-top: 20px;
  border-radius: 40px;
  padding: 15px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: #000;
}
body > #root > section.opp > main > #container-ops > div.free-market > .links a.plans {
  background-color: #D2E9FF;
}
body > #root > section.opp > main > #container-ops > div.free-market > .links a.explanation {
  background-color: #FFB401;
}
body > #root > section.opp > main > #container-ops > div.right {
  width: 500px;
}
body > #root > section.opp > main > #container-ops > div.right > .rounded {
  padding: 25px;
  background-color: #A0A8B6;
}
body > #root > section.opp > main > #container-ops > div.right > .contents {
  margin-top: 20px;
  width: inherit;
  display: grid;
  grid-template-columns: 50% 50%;
}
body > #root > section.opp > main > #container-ops > div.right > .contents > .box-ops {
  width: 95%;
  justify-self: center;
  border-radius: 20px;
  margin: 10px 0px 5px 0px;
  color: #6B7280;
  background-color: #FFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
body > #root > section.opp > main > #container-ops > div.right > .contents > .box-ops > img {
  margin-bottom: 15px;
  width: 23px;
}
body > #root > section.opp > main > #container-ops > div.right > .contents > .box-ops > label {
  font-size: 1.1em;
  margin-bottom: 15px;
}
body > #root > section.opp > main > #container-ops > div.right > .contents > .box-ops > a {
  align-items: flex-end;
  cursor: pointer;
  color: #457EF6;
}
body > #root > section.opp > main > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
@media screen and (max-width: 991px) {
  body > #root > section.opp > main {
    padding: 12px !important;
  }
  body > #root > section.opp > main > .header {
    margin: 0 0 10px;
  }
  body > #root > section.opp > main > #container-ops {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    width: 100%;
    margin-top: 20px;
    padding: 0px;
  }
  body > #root > section.opp > main > #container-ops .rounded {
    padding: 12px 20px;
  }
  body > #root > section.opp > main > #container-ops > div {
    width: 100% !important;
  }
  body > #root > section.opp > main > #container-ops > div.free-market {
    padding: 20px 15px;
  }
  body > #root > section.opp > main > #container-ops > div.right {
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  body > #root > section.opp > main > #container-ops > div.free-market,
  body > #root > section.opp > main > #container-ops > div.right {
    width: 100% !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  body > #root > section.opp > main > #container-ops > div {
    margin: 0px 20px;
  }
  body > #root > section.opp > main > #container-ops > div.free-market {
    width: 50%;
  }
  body > #root > section.opp > main > #container-ops > div.right {
    width: 50%;
  }
  body > #root > section.opp > main > #container-ops > div.right > .contents {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  body > #root > section.opp > main > #container-ops > div {
    margin: 0px 25px;
    width: 100% !important;
  }
}
