/* Font Family */
/* Borda arredondada */
body > #root > section.ccp > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section.ccp > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 1rem;
  height: 35px;
}
body > #root > section.ccp > main > .header > .msg {
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #FFF;
  background-color: #1e931e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.ccp > main > .header > .msg.err {
  background-color: #d62516;
}
body > #root > section.ccp > main > .premisesBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 30px;
  overflow-y: scroll;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.ccp > main > .premisesBox > * > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 40px;
}
body > #root > section.ccp > main > .premisesBox > * > .header > h2 {
  font-size: 1.2rem;
  color: #666;
}
body > #root > section.ccp > main > .premisesBox > .configs-form,
body > #root > section.ccp > main > .premisesBox > .prices-form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs,
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs > div,
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs > div:not(:first-child),
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs > div:not(:first-child) {
  margin-left: 20px;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs > div.inputLabel > label,
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs > div.inputLabel > label {
  font-size: 0.7rem;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs > div.inputLabel > input,
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs > div.inputLabel > input {
  padding: 8px 10px;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > .inputs > div.inputLabel > input:disabled,
body > #root > section.ccp > main > .premisesBox > .prices-form > .inputs > div.inputLabel > input:disabled {
  color: #666;
  background-color: #F0F0F0;
}
body > #root > section.ccp > main > .premisesBox > .configs-form > hr,
body > #root > section.ccp > main > .premisesBox > .prices-form > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  border: none;
}
body > #root > section.ccp > main > .premisesBox > hr {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 50px 0;
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
  border: none;
}
