/* Font Family */
/* Borda arredondada */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZKCM.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVl2ZhZI2eCN5jzbjEETS9weq8-59U.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v25/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meKCM.ttf) format('truetype');
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}
body {
  font-size: 14px;
  color: #333;
}
body * {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}
body a,
body a:visited,
body a:focus {
  text-decoration: none;
  color: #003063;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body a:hover,
body a:visited:hover,
body a:focus:hover {
  color: #001730;
}
body a.dec:hover,
body a:visited.dec:hover,
body a:focus.dec:hover {
  text-decoration: underline;
}
body h1,
body h2,
body h3,
body h4 {
  margin: 0;
}
body > #root {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  min-height: 100vh;
}
body > #root > #newPass {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  background: url('../../../assets/images/backgrounds/waves_dsk.png') no-repeat;
  background-size: cover;
  z-index: 1;
}
body > #root > #newPass:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('../../../assets/images/backgrounds/fork.png') 5% bottom no-repeat;
}
body > #root > #newPass > .header,
body > #root > #newPass > form,
body > #root > #newPass > .boxErrors,
body > #root > #newPass > .boxMessage {
  position: relative;
  margin: 0 auto;
  width: 400px;
}
body > #root > #newPass > .header:not(.boxErrors):not(.boxMessage),
body > #root > #newPass > form:not(.boxErrors):not(.boxMessage),
body > #root > #newPass > .boxErrors:not(.boxErrors):not(.boxMessage),
body > #root > #newPass > .boxMessage:not(.boxErrors):not(.boxMessage) {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
body > #root > #newPass > .header > img {
  height: 60px;
}
body > #root > #newPass > .header > h2 {
  margin-top: 5px;
  font-size: 1.4rem;
  color: #666;
}
body > #root > #newPass > .boxErrors,
body > #root > #newPass > .boxMessage {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 30px;
  padding: 15px 30px;
  color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > #newPass > .boxErrors > span:not(:first-child),
body > #root > #newPass > .boxMessage > span:not(:first-child) {
  margin-top: 5px;
}
body > #root > #newPass > .boxErrors {
  background-color: #EA4335;
}
body > #root > #newPass > .boxMessage {
  background-color: #197e19;
}
body > #root > #newPass > form {
  margin-top: 40px;
  padding: 30px;
  background-color: #FFF;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-box-shadow: 2px 2px 20px -5px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 2px 20px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 20px -5px rgba(0, 0, 0, 0.4);
  z-index: 9;
}
body > #root > #newPass > form > button.send {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  margin-top: 25px;
  padding: 8px 10px;
  font-weight: 600;
  color: #FFF;
  cursor: pointer;
  background-color: #003c7d;
  border: 1px solid #00244a;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > #newPass > form > button.send:hover {
  background-color: #003063;
  border-color: #000b17;
}
body > #root > #newPass > form > .back {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 30px;
  font-size: 0.8rem;
  color: #737373;
}
body > #root > #newPass > form > .back > a {
  margin-left: 5px;
  font-weight: 500;
}
