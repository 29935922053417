/* Font Family */
/* Borda arredondada */
body > #root > section > main .chartsBox.dash {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .chartsBox.dash * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > section > main .chartsBox.dash > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: -20px -20px 0 -20px;
  padding: 25px 20px 20px;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section > main .chartsBox.dash > .header > .monthSelectBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > * {
  padding: 5px 10px;
  color: #4d4d4d;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > *:not(:first-child) {
  margin-left: 5px;
}
body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > *.active {
  color: #FFF;
  background-color: #10B981;
  border-color: #0c8a60;
}
body > #root > section > main .chartsBox.dash > .header > .selectChart {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section > main .chartsBox.dash > .header > .selectChart > label {
  margin-right: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
}
body > #root > section > main .chartsBox.dash > .header > .selectChart > select {
  padding: 5px 10px;
  width: 240px;
  color: #4d4d4d;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section > main .chartsBox.dash > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .chartsBox.dash > .content > .loading > .icon {
  width: 150px;
}
@media screen and (max-width: 991px) {
  body > #root > section > main .chartsBox.dash > .header {
    margin: 0px;
    padding: 10px 0px;
    width: 100%;
  }
  body > #root > section > main .chartsBox.dash > .header > .monthSelectBox {
    width: 50%;
  }
  body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > * {
    padding: 6px 8px;
    font-size: 0.7rem;
  }
  body > #root > section > main .chartsBox.dash > .header > .selectChart {
    width: 50%;
  }
  body > #root > section > main .chartsBox.dash > .header > .selectChart > label {
    margin-right: 10px;
    font-size: 0.75rem;
  }
  body > #root > section > main .chartsBox.dash > .header > .selectChart > select {
    padding: 5px 8px;
    width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section > main .chartsBox.dash > .header {
    padding: 25px 20px 20px;
  }
  body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > * {
    padding: 6px 8px;
    font-size: 0.8rem;
  }
  body > #root > section > main .chartsBox.dash > .header > .monthSelectBox > *:not(:first-child) {
    margin-left: 5px;
  }
  body > #root > section > main .chartsBox.dash > .header > .selectChart > label {
    margin-right: 10px;
    font-size: 0.75rem;
  }
  body > #root > section > main .chartsBox.dash > .header > .selectChart > select {
    padding: 6px 8px;
  }
}
