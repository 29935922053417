/* Font Family */
/* Borda arredondada */
.header-create-account div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header-create-account div a,
.header-create-account div img {
  height: 80px;
  margin-bottom: -10px;
}
.header-create-account h2 {
  font-size: 20px;
  color: #003063;
}
.header-create-account h3 {
  margin: 20px 0px;
  font-size: 16px;
  font-weight: normal;
  color: #374151;
}
