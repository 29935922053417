/* Font Family */
/* Borda arredondada */
body > #root > section.cons > main #moonChart .baseLines {
  fill: #6B7280;
}
body > #root > section.cons > main #moonChart .kwh {
  fill: #9CA3AF;
}
body > #root > section.cons > main #moonChart .middleLine {
  fill: #D0D6DF;
}
body > #root > section.cons > main #moonChart .legends {
  fill: #9CA3AF;
}
body > #root > section.cons > main #moonChart .legends.left.unit {
  transform: matrix(1, 0, 0, 1, -50.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.two-units {
  transform: matrix(1, 0, 0, 1, -54.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.three-units {
  transform: matrix(1, 0, 0, 1, -58.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.four-units {
  transform: matrix(1, 0, 0, 1, -65.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.five-units {
  transform: matrix(1, 0, 0, 1, -69.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.six-units {
  transform: matrix(1, 0, 0, 1, -72.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.seven-units {
  transform: matrix(1, 0, 0, 1, -78.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.eight-units {
  transform: matrix(1, 0, 0, 1, -82.4, 391);
}
body > #root > section.cons > main #moonChart .legends.left.nine-units {
  transform: matrix(1, 0, 0, 1, -85.4, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.unit {
  transform: matrix(1, 0, 0, 1, 89, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.two-units {
  transform: matrix(1, 0, 0, 1, 85, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.three-units {
  transform: matrix(1, 0, 0, 1, 81, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.four-units {
  transform: matrix(1, 0, 0, 1, 74, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.five-units {
  transform: matrix(1, 0, 0, 1, 70, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.six-units {
  transform: matrix(1, 0, 0, 1, 67, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.seven-units {
  transform: matrix(1, 0, 0, 1, 61, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.eight-units {
  transform: matrix(1, 0, 0, 1, 57, 391);
}
body > #root > section.cons > main #moonChart .legends.middle.nine-units {
  transform: matrix(1, 0, 0, 1, 54, 391);
}
body > #root > section.cons > main #moonChart .legends.right.unit {
  transform: matrix(1, 0, 0, 1, 230, 391);
}
body > #root > section.cons > main #moonChart .legends.right.two-units {
  transform: matrix(1, 0, 0, 1, 226, 391);
}
body > #root > section.cons > main #moonChart .legends.right.three-units {
  transform: matrix(1, 0, 0, 1, 222, 391);
}
body > #root > section.cons > main #moonChart .legends.right.four-units {
  transform: matrix(1, 0, 0, 1, 215, 391);
}
body > #root > section.cons > main #moonChart .legends.right.five-units {
  transform: matrix(1, 0, 0, 1, 211, 391);
}
body > #root > section.cons > main #moonChart .legends.right.six-units {
  transform: matrix(1, 0, 0, 1, 208, 391);
}
body > #root > section.cons > main #moonChart .legends.right.seven-units {
  transform: matrix(1, 0, 0, 1, 202, 391);
}
body > #root > section.cons > main #moonChart .legends.right.eight-units {
  transform: matrix(1, 0, 0, 1, 198, 391);
}
body > #root > section.cons > main #moonChart .legends.right.nine-units {
  transform: matrix(1, 0, 0, 1, 195, 391);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .bkg {
  fill: #666;
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month {
  fill: #FFF;
  font-size: 12px;
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.jan {
  transform: matrix(1, 0, 0, 1, -47.8, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.fev {
  transform: matrix(1, 0, 0, 1, -50.6, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.mar,
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.jun,
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.jul {
  transform: matrix(1, 0, 0, 1, -44, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.abr,
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.mai {
  transform: matrix(1, 0, 0, 1, -40.5, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.ago {
  transform: matrix(1, 0, 0, 1, -46, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.set {
  transform: matrix(1, 0, 0, 1, -52.4, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.out {
  transform: matrix(1, 0, 0, 1, -48.2, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.nov {
  transform: matrix(1, 0, 0, 1, -54.5, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .monthMark > .month.dez {
  transform: matrix(1, 0, 0, 1, -52.8, 308.9838);
}
body > #root > section.cons > main #moonChart #lineMonth > .mark > .select {
  fill: #666;
}
body > #root > section.cons > main #moonChart #lineMonth > .mark > .selectInt {
  fill: #FFF;
}
body > #root > section.cons > main #moonChart #lineMonth > .selectPos {
  fill: #C0C0C0;
}
body > #root > section.cons > main #moonChart.green > #lineMonth > .monthMark > .bkg {
  fill: #79BE0A;
}
body > #root > section.cons > main #moonChart.green > #lineMonth > .mark > .select {
  fill: #79BE0A;
}
body > #root > section.cons > main #moonChart.green > #lineMonth > .selectPos {
  fill: #5a8e07;
}
body > #root > section.cons > main #moonChart.red > #lineMonth > .monthMark > .bkg {
  fill: #be0a0a;
}
body > #root > section.cons > main #moonChart.red > #lineMonth > .mark > .select {
  fill: #be0a0a;
}
body > #root > section.cons > main #moonChart.red > #lineMonth > .selectPos {
  fill: #8e0707;
}
