/* Font Family */
/* Borda arredondada */
.selectbox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 0.8rem;
  font-weight: 500;
  overflow: hidden;
  border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  -webkit-border-radius: 0.2em;
}
@media screen and (max-width: 767px) {
  .selectbox {
    flex: 0 0 auto !important;
  }
}
.selectbox > * {
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  background-color: #e3e3e3;
  border: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.selectbox > *:not(:first-child) {
  border-left: 1px solid #F0F0F0;
}
.selectbox > *.active {
  color: #FFF;
  background-color: #006ee3;
}
