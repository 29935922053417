/* Font Family */
/* Borda arredondada */
.modal_file {
  width: 100%;
}
.modal_file .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 35px;
}
.modal_file .buttons > button {
  width: 45%;
}
.modal_file .button {
  margin-top: 35px;
}
.modal_file .button > button {
  width: 100%;
  margin-top: 10px;
}
.modal_file .ok {
  border-color: #2d70b3 !important;
  color: #2d70b3 !important;
}
.modal_file .edit {
  background-color: #2d70b3 !important;
  color: #FFF !important;
}
.modal_file .save {
  background-color: #10B981 !important;
  color: #FFF !important;
}
.modal_file .cancel {
  background-color: #DF0D0D !important;
  color: #FFF !important;
}
body > #root > section.hist > main {
  overflow: scroll;
}
body > #root > section.hist > main > .messageInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 1rem;
}
body > #root > section.hist > main > .messageInfo > .msg {
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #FFF;
  background-color: #1e931e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  position: absolute;
}
body > #root > section.hist > main > .messageInfo > .msg.err {
  background-color: #d62516;
  z-index: 1;
  position: absolute;
}
body > #root > section.hist > main .header {
  display: flex;
  justify-content: space-between;
  height: auto;
}
body > #root > section.hist > main .header > h1 {
  margin-bottom: 1rem;
}
body > #root > section.hist > main .header > input {
  display: none;
}
body > #root > section.hist > main .header > div {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.hist > main .header > div > h3 {
  width: auto;
  font-size: 1rem;
  color: #4d4d4d;
  margin-right: 10px;
}
body > #root > section.hist > main .header > div > button {
  padding: 10px;
}
body > #root > section.hist > main .header > div > button:last-child {
  margin-left: 5px;
}
body > #root > section.hist > main .header > div > button.load {
  color: #FFF;
  background-color: #d88508;
}
body > #root > section.hist > main .header > div > button.load > svg {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 5000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 5000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 5000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root > section.hist > main > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section.hist > main .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section.hist > main .header > div > h3 {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 991px) {
  body > #root > section.hist > main .header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  body > #root > section.hist > main .header > div {
    margin-top: 10px;
  }
  body > #root > section.hist > main .header > div > h3 {
    width: auto;
    font-size: 1rem;
    color: #4d4d4d;
    margin-right: 10px;
  }
}
