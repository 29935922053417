/* Font Family */
/* Borda arredondada */
body > #root > section.opp.model > main > .messageInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 1rem;
}
body > #root > section.opp.model > main > .messageInfo > .msg {
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #FFF;
  background-color: #1e931e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  position: absolute;
}
body > #root > section.opp.model > main > .messageInfo > .msg.err {
  background-color: #d62516;
  z-index: 1;
  position: absolute;
}
body > #root > section.opp.model > main > .content {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-bottom: 30px;
}
body > #root > section.opp.model > main > .content > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
  color: #666;
  z-index: 2;
}
body > #root > section.opp.model > main > .content > .infos > .texts > h1 {
  font-size: 1.8em;
  color: #333;
}
body > #root > section.opp.model > main > .content > .infos > .texts > h3 {
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
}
body > #root > section.opp.model > main > .content > .infos > .texts > div {
  margin: 15px 0;
  line-height: 1.8em;
  font-size: 1em;
  text-align: justify;
}
body > #root > section.opp.model > main > .content > .infos > .texts > div > p {
  margin: 20px 0;
  font-size: 1.2em;
}
body > #root > section.opp.model > main > .content > .infos > .texts > ul {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: 40px 0 20px;
  padding-left: 0;
}
body > #root > section.opp.model > main > .content > .infos > .texts > ul > a {
  width: 100%;
  color: #a7a7a7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.opp.model > main > .content > .infos > .texts > ul > a:hover {
  color: #333;
}
body > #root > section.opp.model > main > .content > .infos > .texts > ul li {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 10px 0;
  width: 45%;
  font-weight: 500;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section.opp.model > main > .content > .infos > .texts > ul li > svg {
  color: #C0C0C0;
}
body > #root > section.opp.model > main > .content > .infos > .buttons > button {
  font-size: 14px;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
body > #root > section.opp.model > main > .content > .infos > .buttons > button.prev {
  margin-right: 10px;
  color: #333;
  border: 2px solid #999;
}
body > #root > section.opp.model > main > .content > .infos > .buttons > button.next {
  color: #F0F0F0;
  background-color: #003063;
}
body > #root > section.opp.model > main > .content > .infos > .buttons > button.plans {
  padding-left: 60px;
  padding-right: 60px;
  color: #000;
  background-color: #FFCC00;
}
body > #root > section.opp.model > main > .content > .icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  margin-left: 2rem;
  min-width: 40%;
  z-index: 1;
}
body > #root > section.opp.model > main > .content > .icon > img {
  max-height: 50vh;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > div {
  color: #003063;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > div > h3 {
  font-size: 2em;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > div > p {
  margin: 5px 0 0 0;
  font-size: 1.3em;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients {
  display: grid;
  margin-left: 40px;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients > a {
  width: 100%;
  display: flex;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients > a > img {
  align-self: center;
  width: 110px;
  background-size: cover;
  background-position: center center;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients > a > img:hover {
  transition: 500ms;
  transform: scale(1.1);
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > ul {
  margin: 20px 0;
}
body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > ul span {
  margin-right: 15px;
}
body > #root > section.opp.model > main > .progress {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 25px 10% 20px;
  background-color: #FFF;
}
body > #root > section.opp.model > main > .progress > .point {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.opp.model > main > .progress > .point.active > .pin {
  background-color: #22A822;
  border-color: #22A822;
}
body > #root > section.opp.model > main > .progress > .point > .pin {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin: auto;
  width: 40px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  background-color: #F0F0F0;
  border: 2px solid #C0C0C0;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.opp.model > main > .progress > .point > .text {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 5px;
  font-size: 0.7rem;
  text-align: center;
}
body > #root > section.opp.model > main > .progress > .line {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin: 17.5px 30px;
  height: 5px;
  background-color: #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.opp.model > main > .progress > .line.active {
  background-color: #197e19;
}
@media screen and (max-width: 991px) {
  body > #root > section.opp.model > main {
    overflow-y: scroll;
  }
  body > #root > section.opp.model > main:not(.load) {
    height: calc(100vh - 215px);
    /* Fallback for browsers that do not support Custom Properties */
    height: calc((var(--vh, 1vh) * 100) - 215px);
  }
  body > #root > section.opp.model > main > .headLine {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section.opp.model > main > .headLine > .perc > .info > .number {
    font-size: 28px;
  }
  body > #root > section.opp.model > main > .headLine > .perc > .info > .lab {
    margin-top: 5px;
    font-size: 13px;
  }
  body > #root > section.opp.model > main > .headLine > .eco {
    margin-left: 0px;
  }
  body > #root > section.opp.model > main > .headLine > .eco > .info {
    font-size: 28px;
  }
  body > #root > section.opp.model > main > .content {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin-bottom: 0;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts {
    width: 100%;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > div {
    margin: 15px 0;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > div > p {
    font-size: 0.85rem;
    line-height: 1.3rem;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > ul {
    margin-top: 25px;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > ul > li {
    width: 100%;
  }
  body > #root > section.opp.model > main > .content > .infos > .buttons > button {
    margin-top: 10px;
  }
  body > #root > section.opp.model > main > .content > .icon {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin: 20px 0 30px;
    min-width: initial;
  }
  body > #root > section.opp.model > main > .content > .icon > img {
    width: 65%;
    max-height: initial;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    text-align: center;
    max-width: 100%;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > div > h3 {
    font-size: 1.6em;
    padding: 0px;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > div > p {
    font-size: 1.2em;
    padding: 0px;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients {
    margin: 0;
    grid-template-columns: 25% 25% 25%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: center;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples > .my_clients > a > img {
    width: 100%;
  }
  body > #root > section.opp.model > main > .progress {
    position: fixed;
    bottom: 75px;
    left: 0;
    right: 0;
    padding: 16px 25px;
    height: 90px;
    border-bottom: 1px solid #F0F0F0;
    background-color: rgba(255, 255, 255, 0.5);
  }
  body > #root > section.opp.model > main > .progress > .point > .pin {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
  }
  body > #root > section.opp.model > main > .progress > .point > .text {
    margin-top: 5px;
    font-size: 0.5rem;
    text-align: center;
  }
  body > #root > section.opp.model > main > .progress > .line {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin: 13px 15px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > section.opp.model > main {
    overflow-y: auto;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > div {
    margin: 20px 0;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > div > p {
    margin: 15px 0;
    line-height: 1.4rem;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > h3 {
    margin-bottom: 25px;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > ul {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin-top: 35px;
  }
  body > #root > section.opp.model > main > .content > .infos > .texts > ul > li {
    width: 50%;
  }
  body > #root > section.opp.model > main > .content > .icon {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    margin-left: 2rem;
    min-width: 35%;
  }
  body > #root > section.opp.model > main > .content > .icon > img {
    max-height: 40vh;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > .examples {
    margin: 40px 0;
    max-width: 100%;
  }
  body > #root > section.opp.model > main.freeMarket > .content > .infos > .texts > ul {
    margin: 40px 0;
    font-size: 1em;
  }
  body > #root > section.opp.model > main > .progress {
    padding: 20px 10% 15px;
    background-color: #FFF;
  }
  body > #root > section.opp.model > main > .progress > .point > .pin {
    width: 35px;
    height: 35px;
    font-size: 0.8rem;
    border-radius: 35px;
    -moz-border-radius: 35px;
    -webkit-border-radius: 35px;
  }
  body > #root > section.opp.model > main > .progress > .point > .text {
    font-size: 0.65rem;
  }
  body > #root > section.opp.model > main > .progress > .line {
    margin: 16px 25px;
  }
}
