/* Font Family */
/* Borda arredondada */
body > #root > section.tariff > main {
  overflow: auto;
}
body > #root > section.tariff > main > h2 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 30px;
  font-size: 1.1rem;
  color: #4d4d4d;
}
body > #root > section.tariff > main > .container {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.tariff > main > .container > .left,
body > #root > section.tariff > main > .container > .right {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.tariff > main > .container > .left > .title,
body > #root > section.tariff > main > .container > .right > .title {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin: 0 auto 30px;
  width: 90%;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-align: center;
  color: #666;
}
body > #root > section.tariff > main > .container > .left {
  margin-right: 40px;
  width: 60%;
  border: 1px solid #D0D0D0;
  border-radius: 15px;
  background-color: #FFF;
}
body > #root > section.tariff > main > .container > .right {
  width: 40%;
}
body > #root > section.tariff > main > .container > .right > .alert {
  margin-bottom: 20px;
  padding: 40px 30px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: center;
  color: #666;
  background-color: #fbd9d7;
  border: 2px dashed #EA4335;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.tariff > main > .container > .right > .corr {
  margin-bottom: 20px;
  width: 100%;
  padding: 20px 0px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: center;
  color: #666;
  background-color: #e8fae8;
  border: 2px dashed #22A822;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
body > #root > section.tariff > main > .container > .right > .date {
  font-size: 0.7rem;
  color: #999;
}
body > #root > section.tariff > main > .container > .right > .date > svg {
  margin-right: 5px;
}
@media screen and (max-width: 991px) {
  body > #root > section.tariff > main {
    min-height: 100%;
  }
  body > #root > section.tariff > main > .container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > section.tariff > main > .container > .left,
  body > #root > section.tariff > main > .container > .right {
    margin: 0 !important;
    width: 100% !important;
    min-width: initial !important;
  }
  body > #root > section.tariff > main > .container > .left > div,
  body > #root > section.tariff > main > .container > .right > div {
    height: auto !important;
  }
  body > #root > section.tariff > main > .container .right {
    margin-top: 20px !important;
  }
}
