/* Font Family */
/* Borda arredondada */
body > #root > section > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section > main > .messageInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 1rem;
}
body > #root > section > main > .messageInfo > .msg {
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #FFF;
  background-color: #1e931e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  position: absolute;
}
body > #root > section > main > .messageInfo > .msg.err {
  background-color: #d62516;
  z-index: 1;
  position: absolute;
}
body > #root > section > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  height: 35px;
}
body > #root > section.cli-special-condition > main > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  overflow-y: scroll;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.cli-special-condition > main > form > .fields {
  padding: 30px;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}
body > #root > section.cli-special-condition > main > form > .fields > div {
  width: 100%;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left:not(:first-child) {
  margin-left: 10%;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left > h2 {
  margin-bottom: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #a6a6a6;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left > .checkbox > label {
  padding-left: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #595959;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left > .checkbox > input[type="checkbox"] {
  width: 1.5em;
  height: 1.5em;
  border-color: #C0C0C0;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left > .checkbox > input[type="checkbox"]::before {
  width: 1.5em;
  height: 1.5em;
  box-shadow: inset 1.5em 1.5em #006ee3;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .left > .selectbox {
  margin-top: 15px;
  border-color: #C0C0C0;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .btns > a,
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .btns > button {
  padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .btns > a:not(:first-child),
body > #root > section.cli-special-condition > main > form > .fields > div > .header-infos > .btns > button:not(:first-child) {
  margin-left: 15px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > hr {
  margin: 25px 0;
  height: 1px;
  background-color: #eaeaea;
  border: none;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-bottom: 10px;
  margin-top: 20px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #F0F0F0;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup .MuiFormControl-root {
  position: absolute;
  visibility: hidden;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup .MuiFormControl-root,
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup .MuiFormControl-root * {
  height: 1px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .dateBox {
  position: relative;
  margin-right: 10px;
  width: 100px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .dateBox > .inputLabel > label {
  font-size: 0.85em;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .dateBox > .inputLabel > input {
  padding: 10px;
  cursor: pointer;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .noMargin {
  margin: 0 !important;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > p {
  color: #666;
  font-size: 1.1em;
  margin-right: 20px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel {
  margin-bottom: 15px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 200px;
  margin: 0;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel:not(:last-child),
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel:not(:last-child) {
  margin-right: 10px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel.typeDis,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel.typeDis,
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel.dateYM,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel.dateYM {
  width: 100px;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > label,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > label {
  font-size: 0.85em;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > input,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > input,
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > select,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > select {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: initial !important;
  -ms-flex-positive: initial !important;
  flex-grow: initial !important;
  width: 100%;
  background-color: #FFF;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > input:disabled,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > input:disabled,
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > select:disabled,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > select:disabled {
  background-color: #F0F0F0;
}
body > #root > section.cli-special-condition > main > form > .fields > div > .planLine > .planGroup > .inputLabel > input,
body > #root > section.cli-special-condition > main > form > .fields > div > .inputLabel > input {
  padding: 10px;
}
