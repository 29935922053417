/* Font Family */
/* Borda arredondada */
body > #root > main#onboarding {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 70px;
}
body > #root > main#onboarding > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  position: relative;
}
body > #root > main#onboarding > .infos > a.logout {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
}
body > #root > main#onboarding > .infos > a.logout > span {
  margin-right: 5px;
  font-size: 12px;
}
body > #root > main#onboarding > .infos > img {
  width: 300px;
}
body > #root > main#onboarding > .chatbot {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 450px;
  background-color: #061731;
}
body > #root > main#onboarding > .chatbot > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 15px 20px;
  background-color: #030C1A;
}
body > #root > main#onboarding > .chatbot > header > h2 {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  color: #FFF;
}
body > #root > main#onboarding > .chatbot > header > img {
  width: 50px;
}
body > #root > main#onboarding > .chatbot > section {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-content: flex-end;
  align-self: stretch;
  position: relative;
  padding: 20px;
  max-height: 91vh;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: #061731;
}
body > #root > main#onboarding > .chatbot > section > .balon,
body > #root > main#onboarding > .chatbot > section > .resp {
  margin-top: 10px;
}
body > #root > main#onboarding > .chatbot > section > .question {
  margin-top: 50px;
}
body > #root > main#onboarding > .chatbot > section > .points {
  margin-top: 15px;
}
body > #root > main#onboarding > .chatbot > section > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > main#onboarding > .chatbot > section > .buttons > button.init {
  padding: 10px 20px;
  color: #FFF;
  cursor: pointer;
  background-color: #0e3675;
  border: none;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}
@media screen and (max-width: 767px) {
  body > #root > main#onboarding {
    height: 100vh;
  }
  body > #root > main#onboarding .infos {
    display: none !important;
  }
  body > #root > main#onboarding .chatbot {
    width: 100vw;
  }
  body > #root > main#onboarding .chatbot > section > .modal {
    width: 100%;
  }
  body > #root > main#onboarding .chatbot > section > .modal > .box {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
}
