/* Font Family */
/* Borda arredondada */
body > #root > main.term {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 100px 0px;
  height: 100vh;
  color: #666;
  text-align: justify;
  overflow-y: scroll;
}
body > #root > main.term > h1 {
  margin-bottom: 100px;
}
body > #root > main.term .body_term {
  margin: auto;
  width: 40%;
  color: #666;
  text-align: justify;
}
body > #root > main.term .body_term > h4 {
  margin-bottom: 25px;
  font-size: 0.9em;
  text-align: center;
}
body > #root > main.term .body_term > h5 {
  margin-top: 40px;
  font-size: 0.85em;
}
body > #root > main.term .body_term > p {
  line-height: 1.5em;
}
@media screen and (max-width: 991px) {
  body > #root > main.term {
    padding: 50px;
  }
  body > #root > main.term .body_term {
    margin: 0px;
    width: 100%;
    height: auto;
  }
}
