/* Font Family */
/* Borda arredondada */
body > #root > section.cliBillError > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section.cliBillError > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  height: 35px;
}
body > #root > section.cliBillError > main > .header > .messageInfo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 1rem;
}
body > #root > section.cliBillError > main > .header > .messageInfo > .msg {
  padding: 10px 15px;
  font-size: 0.75rem;
  color: #FFF;
  background-color: #1e931e;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  position: absolute;
}
body > #root > section.cliBillError > main > .header > .messageInfo > .msg.err {
  background-color: #d62516;
  z-index: 1;
  position: absolute;
}
body > #root > section.cliBillError > main > .header > h3 {
  font-size: 0.9rem;
  color: #c3c3c3;
}
body > #root > section.cliBillError > main > .header > h3 > b {
  color: #C0C0C0;
}
body > #root > section.cliBillError > main > .header > .searchBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  width: 31.75%;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 8px 30px 8px 10px;
  width: 100%;
  font-size: 0.8rem;
  color: #666;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:focus {
  color: #333;
  background-color: #FFF;
  border-color: #666;
  outline: none;
  outline-width: 0;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:disabled {
  color: #D0D0D0;
  background-color: #F0F0F0;
  border-color: #D0D0D0;
}
body > #root > section.cliBillError > main > .header > .searchBox > #search:disabled::placeholder {
  color: #C0C0C0;
}
body > #root > section.cliBillError > main > .header > .searchBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  color: #EA4335;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
body > #root > section.cliBillError > main > .billsErrorsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.cliBillError > main > .billsErrorsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1rem 1.5rem 1rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section.cliBillError > main > .billsErrorsBox > header > .blank {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.cliBillError > main > .billsErrorsBox > header > .client {
  width: 50%;
}
body > #root > section.cliBillError > main > .billsErrorsBox > header > .date {
  width: 20%;
}
body > #root > section.cliBillError > main > .billsErrorsBox > header > .btns {
  width: 20%;
}
body > #root > section.cliBillError > main > .billsErrorsBox > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 75vh;
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C0C0C0 #e3e3e3;
  /* Works on Chrome, Edge, and Safari */
}
body > #root > section.cliBillError > main > .billsErrorsBox > section > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
body > #root > section.cliBillError > main > .billsErrorsBox > section::-webkit-scrollbar-track {
  background: #e3e3e3;
}
body > #root > section.cliBillError > main > .billsErrorsBox > section::-webkit-scrollbar {
  width: 5px;
}
body > #root > section.cliBillError > main > .billsErrorsBox > section::-webkit-scrollbar-thumb {
  margin: 2px;
  background-color: #C0C0C0;
}
/* .dark > body > #root > section.cliBillError > main {
} */
