/* Font Family */
/* Borda arredondada */
body > div > div > iframe {
  bottom: 16px !important;
}
@media screen and (max-width: 991px) {
  body > div > div > iframe {
    bottom: 135px !important;
  }
}
body > #root > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #e6e6e6;
}
body > #root > header > .logo {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
body > #root > header > .logo:before {
  position: absolute;
  content: '';
  top: 25%;
  right: -1px;
  display: block;
  width: 2px;
  height: 50%;
  background-color: #D0D0D0;
}
body > #root > header > .logo > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > header > .logo > a img {
  height: 70%;
}
body > #root > header > .section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > header > .section > .breadBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > header > .section > .breadBox > .back {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
  font-size: 1.2em;
  border: none;
}
body > #root > header > .section > .breadBox > .breadcrumbs {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0 30px 0 0;
}
body > #root > header > .section > .breadBox > .breadcrumbs > .links {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > header > .section > .breadBox > .breadcrumbs > .links > a,
body > #root > header > .section > .breadBox > .breadcrumbs > .links > span {
  position: relative;
  font-size: 0.7rem;
  color: #999;
}
body > #root > header > .section > .breadBox > .breadcrumbs > .links > a:not(:first-child),
body > #root > header > .section > .breadBox > .breadcrumbs > .links > span:not(:first-child) {
  margin-left: 20px;
}
body > #root > header > .section > .breadBox > .breadcrumbs > .links > a:not(:first-child):before,
body > #root > header > .section > .breadBox > .breadcrumbs > .links > span:not(:first-child):before {
  content: '';
  position: absolute;
  top: 5px;
  left: -12px;
  display: block;
  width: 4px;
  height: 4px;
  font-size: 10px;
  background-color: #D0D0D0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
body > #root > header > .section > .breadBox > .breadcrumbs > .links > span {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > header > .section > .breadBox > .breadcrumbs > .curr {
  font-size: 1rem;
  color: #666666;
  font-weight: 600;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
body > #root > header > .section > .buttonsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 20px;
}
body > #root > header > .section > .buttonsBox > select {
  margin-right: 30px;
  padding: 6px 10px;
  color: #333;
  background-color: #FFF;
  border: 1px solid #999;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}
body > #root > header > .section > .buttonsBox > select:disabled {
  color: #666;
  background-color: #D0D0D0;
  border-color: #C0C0C0;
}
body > #root > header > .section > .buttonsBox > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 6px;
  border: none;
  background-color: #999;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > header > .section > .buttonsBox > button.theme {
  margin-right: 50px;
}
body > #root > header > .section > .buttonsBox > button.bell {
  color: #999;
  background-color: #f2f2f2;
}
body > #root > header > .section > .buttonsBox > button.bell:hover {
  color: #666;
  background-color: #e6e6e6;
}
body > #root > header > .section > .buttonsBox > button.bell:hover > svg {
  -webkit-animation-name: bell;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: bell;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: bell;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: bell;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
body > #root > header > .section > .buttonsBox > .foto {
  margin-left: 10px;
  width: 40px;
  height: 40px;
}
body > #root > header > .section > .buttonsBox > .foto > img {
  max-height: 100%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox {
  left: auto !important;
  right: 0 !important;
  padding: 1.5rem 1.5rem;
  width: 350px !important;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox > a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox > a:not(:last-child) {
  margin-bottom: 1.5rem;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox > a > .icon {
  width: 12%;
  font-size: 1rem;
  color: #999;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox > a > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.7rem;
  color: #666;
}
body > #root > header > .section > .buttonsBox > .foto > #userBox > a > .infos > span:first-child {
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}
@media screen and (max-width: 991px) {
  body > #root > header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 3 !important;
    -ms-flex-positive: 3 !important;
    flex-grow: 3 !important;
    width: 100vw;
    max-height: 50px;
  }
  body > #root > header > .logo {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    width: auto;
    max-width: none;
    min-width: auto;
  }
  body > #root > header > .logo:before {
    display: none !important;
  }
  body > #root > header > .logo > a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  body > #root > header > .logo > a > img {
    height: 80%;
  }
  body > #root > header > .back,
  body > #root > header > .logout,
  body > #root > header > .blank {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    width: 50px;
    height: 100%;
    font-size: 1.2em;
    border: none;
  }
}
@media screen and (min-width: 992px) and (max-width: 1919px) {
  body > #root > header > .logo {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
  }
  body > #root > header > .logo > img {
    height: 60%;
  }
}
@-ms-keyframes bell {
  0% {
    -ms-transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(15deg);
  }
  50% {
    -ms-transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(-15deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}
@-moz-keyframes bell {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(15deg);
  }
  50% {
    -moz-transform: rotate(0deg);
  }
  75% {
    -moz-transform: rotate(-15deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes bell {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(15deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes bell {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
