/* Font Family */
/* Borda arredondada */
body > #root > section > main .billBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1rem;
  background-color: #FFF;
}
body > #root > section > main .billBox:not(:first-child) {
  border-top: 1px solid #D0D0D0;
}
body > #root > section > main .billBox > .icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 50px;
}
body > #root > section > main .billBox > .icon > img {
  height: 80%;
}
body > #root > section > main .billBox > .blank {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section > main .billBox > .provider {
  width: 20%;
}
body > #root > section > main .billBox > .client {
  width: 50%;
}
body > #root > section > main .billBox > .modality {
  width: 15%;
}
body > #root > section > main .billBox > .modality > span {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.35rem 0.8rem;
  width: 100px;
  font-size: 0.8rem;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
}
body > #root > section > main .billBox > .modality.green > span {
  color: #115311;
  background-color: #d3f6d3;
}
body > #root > section > main .billBox > .modality.blue > span {
  color: #183d62;
  background-color: #d2e3f4;
}
body > #root > section > main .billBox > .date {
  width: 20%;
}
body > #root > section > main .billBox > .status {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 15%;
}
body > #root > section > main .billBox > .status > span.mark {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  background-color: #999;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > section > main .billBox > .status.validated > span {
  color: #1e931e;
}
body > #root > section > main .billBox > .status.validated > span.mark {
  background-color: #115311;
}
body > #root > section > main .billBox > .status.errors > span {
  color: #ec584c;
}
body > #root > section > main .billBox > .status.errors > span.mark {
  background-color: #d62516;
}
body > #root > section > main .billBox > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 20%;
}
body > #root > section > main .billBox > .buttons > * {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0.50rem 0.75rem;
  text-align: center;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section > main .billBox > .buttons > *:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main .billBox > .buttons > *.check {
  padding-left: 2rem;
  padding-right: 2rem;
}
body > #root > section > main .billBox > .buttons > *.down.load {
  padding: 6px 0.6rem;
  color: #FFF;
  background-color: #e37d1d;
  border-color: #894b11;
}
body > #root > section > main .billBox > .buttons > *.down.load > svg {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
