/* Font Family */
/* Borda arredondada */
body > #root > section > main {
  padding: 30px;
  overflow: hidden;
}
body > #root > section > main > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 20px;
  height: 35px;
}
body > #root > section.opps > main {
  overflow: hidden;
}
body > #root > section.opps > main > .header {
  margin-bottom: 1rem;
}
body > #root > section.opps > main > .oppsBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.opps > main > .oppsBox > header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #D0D0D0;
}
body > #root > section.opps > main > .oppsBox > header > .date,
body > #root > section.opps > main > .oppsBox > header > .btns {
  padding-left: 30px;
}
body > #root > section.opps > main > .oppsBox > header > .name {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  width: 60%;
}
body > #root > section.opps > main > .oppsBox > header > .date {
  width: 20%;
}
body > #root > section.opps > main > .oppsBox > header > .btns {
  width: 20%;
}
body > #root > section.opps > main > .oppsBox > section {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  height: 75vh;
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #C0C0C0 #e3e3e3;
  /* Works on Chrome, Edge, and Safari */
}
body > #root > section.opps > main > .oppsBox > section > .noData {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  font-size: 0.9rem;
  color: #d62516;
}
body > #root > section.opps > main > .oppsBox > section::-webkit-scrollbar-track {
  background: #e3e3e3;
}
body > #root > section.opps > main > .oppsBox > section::-webkit-scrollbar {
  width: 5px;
}
body > #root > section.opps > main > .oppsBox > section::-webkit-scrollbar-thumb {
  margin: 2px;
  background-color: #C0C0C0;
}
body > #root > section.oppsEdit > main > .header > h1 > small {
  font-size: 1.2rem;
}
body > #root > section.oppsEdit > main > form {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 30px;
  background-color: #FFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}
body > #root > section.oppsEdit > main > form > .fields,
body > #root > section.oppsEdit > main > form > div > .fields {
  width: 48%;
}
body > #root > section.oppsEdit > main > form > .fields > h2:not(:first-child),
body > #root > section.oppsEdit > main > form > div > .fields > h2:not(:first-child) {
  margin-top: 30px;
}
body > #root > section.oppsEdit > main > form > .fields > h2,
body > #root > section.oppsEdit > main > form > div > .fields > h2 {
  margin-bottom: 5px;
  font-size: 1.3rem;
  font-weight: 500;
  color: #a6a6a6;
}
body > #root > section.oppsEdit > main > form > .fields > div > .toolbarEditor,
body > #root > section.oppsEdit > main > form > div > .fields > div > .toolbarEditor {
  margin: 0;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
}
body > #root > section.oppsEdit > main > form > .fields > div > .contentEditor,
body > #root > section.oppsEdit > main > form > div > .fields > div > .contentEditor {
  padding: 15px 20px;
  border: 1px solid #F0F0F0;
  border-top: 0px;
  border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
}
body > #root > section.oppsEdit > main > form > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
body > #root > section.oppsEdit > main > form > .btns > a,
body > #root > section.oppsEdit > main > form > .btns > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-left: 15px;
  padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
  color: #666;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #C0C0C0;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.oppsEdit > main > form > .btns > a > span,
body > #root > section.oppsEdit > main > form > .btns > button > span {
  margin-left: 10px;
}
body > #root > section.oppsEdit > main > form > .btns > a:hover.save,
body > #root > section.oppsEdit > main > form > .btns > button:hover.save {
  color: #197e19;
  border-color: #197e19;
}
body > #root > section.oppsEdit > main > form > .btns > a:hover.cancel,
body > #root > section.oppsEdit > main > form > .btns > button:hover.cancel {
  color: #d62516;
  border-color: #d62516;
}
body > #root > section.oppsEdit > main > #editBox {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  margin-top: 1rem;
}
body > #root > section.oppsEdit > main > #editBox > .header {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.oppsEdit > main > #editBox > .header > button.box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  padding: 15px 20px;
  width: 25%;
  font-size: 0.8rem;
  color: #666;
  cursor: pointer;
  background-color: #e3e3e3;
  border: none;
  border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  -webkit-border-radius: 10px 10px 0 0;
}
body > #root > section.oppsEdit > main > #editBox > .header > button.box:not(:first-child) {
  margin-left: 1px;
}
body > #root > section.oppsEdit > main > #editBox > .header > button.box.active {
  font-weight: 600;
  color: #333;
  background-color: #FFF;
}
body > #root > section.oppsEdit > main > #editBox > .contents {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  background-color: #FFF;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
  display: none !important;
  padding: 2.5rem;
  color: #666;
  background-color: #FFF;
  border-bottom: 1px solid #e3e3e3;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box.active {
  display: flex !important;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box[data-editable="true"]:focus,
body > #root > section.oppsEdit > main > #editBox > .contents > .box[data-editable="true"]:active {
  outline: none;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h1,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h2,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h3 {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-bottom: 15px;
  font-weight: 600;
  color: #333;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h1 > *:not(b),
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h2 > *:not(b),
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h3 > *:not(b),
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h1 i,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h2 i,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h3 i,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h1 u,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h2 u,
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h3 u {
  margin: 0 5px;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h1 {
  font-size: 1.6rem;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h2 {
  font-size: 1.3rem;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .box > h3 {
  font-size: 1rem;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 2rem;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .btns > a,
body > #root > section.oppsEdit > main > #editBox > .contents > .btns > button {
  margin-left: 15px;
  padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
}
body > #root > section.oppsEdit > main > #editBox > .contents > .btns > a:not(:first-child),
body > #root > section.oppsEdit > main > #editBox > .contents > .btns > button:not(:first-child) {
  margin-left: 15px;
}
