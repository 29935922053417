/* Font Family */
/* Borda arredondada */
body > #root > section > main .planLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.7rem 15px;
  width: 100%;
  font-size: 0.9rem;
  color: #999;
}
body > #root > section > main .planLine:not(:first-child) {
  border-top: 1px solid #eaeaea;
}
body > #root > section > main .planLine > .type,
body > #root > section > main .planLine > .plan,
body > #root > section > main .planLine > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding-left: 30px;
}
body > #root > section > main .planLine > .provider {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 18%;
}
body > #root > section > main .planLine > .type {
  width: 9%;
}
body > #root > section > main .planLine > .plan {
  width: 16%;
}
body > #root > section > main .planLine > .plan > b {
  margin-right: 8px;
}
body > #root > section > main .planLine > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 25%;
}
body > #root > section > main .planLine > .btns > a:not(:first-child),
body > #root > section > main .planLine > .btns > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main .planLine > .btns > a.bills:hover:not(:disabled),
body > #root > section > main .planLine > .btns > button.bills:hover:not(:disabled) {
  color: #1e931e;
  border-color: #197e19;
}
body > #root > section > main .planLine > .btns > a.errors:hover:not(:disabled),
body > #root > section > main .planLine > .btns > button.errors:hover:not(:disabled) {
  color: #e25d04;
  border-color: #c95203;
}
