/* Font Family */
/* Borda arredondada */
body > #root > section.prof > main {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.prof > main > div {
  width: 35%;
}
body > #root > section.prof > main > div > h1 {
  margin-bottom: 40px;
  font-size: 2.2em;
  font-weight: 500;
  color: #999;
}
body > #root > section.prof > main > div > form > hr {
  margin: 25px 0 20px;
}
body > #root > section.prof > main > div > form > div {
  margin-bottom: 15px;
}
body > #root > section.prof > main > div > form > div > label {
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #595959;
}
body > #root > section.prof > main > div > form > div > input {
  margin: 1px 0 0;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  background-color: transparent;
  color: #666;
  border: 1px solid #D0D0D0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body > #root > section.prof > main > div > form > div > input:focus,
body > #root > section.prof > main > div > form > div > input:active {
  color: #333;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: #C0C0C0;
  outline: none;
}
body > #root > section.prof > main > div > form > div > input::-webkit-input-placeholder {
  color: #999;
}
body > #root > section.prof > main > div > form > div > input:-ms-input-placeholder {
  color: #999;
}
body > #root > section.prof > main > div > form > div > input::placeholder {
  color: #999;
}
body > #root > section.prof > main > div:not(.default) > .buttons {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 2.5rem;
  justify-content: space-between !important;
}
body > #root > section.prof > main > div:not(.default) > .buttons > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > section.prof > main > div:not(.default) > .buttons > button {
  padding: 0.6rem 1.5rem;
}
body > #root > section.prof > main > div.default > .buttons > button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  margin-top: 20px;
  padding: 0.5rem 0;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #d7d7d7;
}
body > #root > section.prof > main > div.default > .buttons > button > .infos {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
body > #root > section.prof > main > div.default > .buttons > button > .infos > span {
  font-size: 0.8rem;
  color: #999;
}
body > #root > section.prof > main > div.default > .buttons > button > .infos > span:first-child {
  margin-bottom: 3px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #666;
}
body > #root > section.prof > main > div.default > .buttons > button > .btn {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  font-size: 1.5rem;
  color: #666;
}
@media screen and (max-width: 991px) {
  body > #root > section.prof > main {
    min-height: 100%;
    background-color: #FFF;
  }
  body > #root > section.prof > main > .default,
  body > #root > section.prof > main .infos,
  body > #root > section.prof > main .contact {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
    margin: 0 !important;
    width: 100% !important;
    min-width: initial !important;
  }
  body > #root > section.prof > main > .default > form,
  body > #root > section.prof > main .infos > form,
  body > #root > section.prof > main .contact > form,
  body > #root > section.prof > main > .default .buttons,
  body > #root > section.prof > main .infos .buttons,
  body > #root > section.prof > main .contact .buttons {
    width: 100%;
  }
  body > #root > section.prof > main > .default > h1,
  body > #root > section.prof > main .infos > h1,
  body > #root > section.prof > main .contact > h1 {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 0px;
  }
  body > #root > section.prof > main > .default:not(.default) > .buttons,
  body > #root > section.prof > main .infos:not(.default) > .buttons,
  body > #root > section.prof > main .contact:not(.default) > .buttons {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between !important;
  }
  body > #root > section.prof > main > .default > .center,
  body > #root > section.prof > main .infos > .center,
  body > #root > section.prof > main .contact > .center,
  body > #root > section.prof > main > .default > .right,
  body > #root > section.prof > main .infos > .right,
  body > #root > section.prof > main .contact > .right {
    margin-top: 20px !important;
  }
}
.dark > body > #root > section.prof > main > div > form > hr {
  border-color: #595959;
}
.dark > body > #root > section.prof > main > div > form > div > label {
  color: #C0C0C0;
}
.dark > body > #root > section.prof > main > div > form > div > input {
  color: #999;
  border-color: #666;
}
.dark > body > #root > section.prof > main > div > form > div > input:focus,
.dark > body > #root > section.prof > main > div > form > div > input:active {
  color: #D0D0D0;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #999;
}
.dark > body > #root > section.prof > main > div > form > div > input::-webkit-input-placeholder {
  color: #666;
}
.dark > body > #root > section.prof > main > div > form > div > input:-ms-input-placeholder {
  color: #666;
}
.dark > body > #root > section.prof > main > div > form > div > input::placeholder {
  color: #666;
}
.dark > body > #root > section.prof > main > div:not(.default) > .buttons > button.back {
  color: #F0F0F0;
  border: 2px solid #C0C0C0;
}
.dark > body > #root > section.prof > main > div:not(.default) > .buttons > button.save {
  color: #0b1700;
  background-color: #c9ff96;
}
.dark > body > #root > section.prof > main > div.default > .buttons > button {
  border-color: #4d4d4d;
}
.dark > body > #root > section.prof > main > div.default > .buttons > button > .infos > span {
  color: #999;
}
.dark > body > #root > section.prof > main > div.default > .buttons > button > .infos > span:first-child {
  color: #D0D0D0;
}
.dark > body > #root > section.prof > main > div.default > .buttons > button > .btn {
  color: #666;
}
