/* Font Family */
/* Borda arredondada */
body > #root .points {
  width: 80px;
  background-color: #082148;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}
body > #root .points > .icon {
  height: 50px;
}
