/* Font Family */
/* Borda arredondada */
body > #root > section > main .clientLine {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  padding: 0.7rem 1rem;
  width: 100%;
  font-size: 0.9rem;
  color: #999;
}
body > #root > section > main .clientLine:not(:first-child) {
  border-top: 1px solid #eaeaea;
}
body > #root > section > main .clientLine > .btns {
  padding-left: 30px;
}
body > #root > section > main .clientLine > .image {
  width: 3%;
}
body > #root > section > main .clientLine > .image > img {
  width: 100%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
body > #root > section > main .clientLine > .name {
  width: 45%;
  padding-left: 0.9rem;
}
body > #root > section > main .clientLine > .btns {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
  width: 65%;
}
body > #root > section > main .clientLine > .btns > a:not(:first-child),
body > #root > section > main .clientLine > .btns > button:not(:first-child) {
  margin-left: 10px;
}
body > #root > section > main .clientLine > .btns > a.bills:hover:not(:disabled),
body > #root > section > main .clientLine > .btns > button.bills:hover:not(:disabled) {
  color: #1e931e;
  border-color: #197e19;
}
body > #root > section > main .clientLine > .btns > a.errors:hover:not(:disabled),
body > #root > section > main .clientLine > .btns > button.errors:hover:not(:disabled) {
  color: #e25d04;
  border-color: #c95203;
}
